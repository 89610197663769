import { createSlice } from "@reduxjs/toolkit";
export const docSlice = createSlice({
  name: "docSlice",
  initialState: {
    currentDoc: {
      docType: "",
      docName: "",
      docFile: null,
    },
    docList: [],
  },
  reducers: {
    addDocument: (state, action) => {
      let tempObj = {
        base: action?.payload?.base,
        id: action?.payload?.index,
        docType: state?.currentDoc?.docType,
        docName: state?.currentDoc?.docName,
        docValue: state?.currentDoc?.docFile,
        uploadedBy: action?.payload?.userName,
        uploadedOn: action?.payload?.uploadedOn,
      };
      state.docList.push(tempObj);
      state.currentDoc.docType = "";
      state.currentDoc.docName = "";
      state.currentDoc.docFile = null;
    },
    updateCurrentDoc: (state, action) => {
      state.currentDoc[action.payload.name] = action.payload.value;
    },
    removeCurrentDoc: (state, action) => {
      state.currentDoc.docFile = null;
    },
  },
});
export const { addDocument, updateCurrentDoc, removeCurrentDoc } =
  docSlice.actions;
export default docSlice.reducer;
