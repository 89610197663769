import React from "react";
import "./button.css";
function BtnOutline(props) {
  const {
    onClick = () => {},
    title = "",
    disabled = false,
    type = "button",
    img = "",
    style = {},
  } = props;
  return (
    <div className="d-flex justify-content-center">
      <button
        style={{ ...style }}
        onClick={onClick}
        disabled={disabled}
        type={type}
        className="btn-out-section "
      >
        {img} {title}
      </button>
    </div>
  );
}

export default BtnOutline;
