import { styled, Typography } from "@mui/material";
import React from "react";
const TableTypo = styled(Typography)(({ theme }) => ({
  fontSize: "0.9rem ",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "1.8%",
}));
export default function tabletypo(props) {
  return (
    <TableTypo
      // align={props?.align ? props.align : ""}
      // variant={props?.variant ? props.variant : ""}
      // color="#5B5675"
      style={{ fontSize: "var(--fs-small)" }}
    >
      {props.children}
    </TableTypo>
  );
}
