import { createSlice } from "@reduxjs/toolkit";
export const orderSlice = createSlice({
  name: "orderspecific",
  initialState: {
    orderDetailsFromAPI: {
      data: {},
      fullDetails: {},
    },
    orderNumber: {
      orderNumber: "",
      cw1OrderNumber: "",
      orderDate: "",
      sendingAgent: "",
      receivingAgent: "",
    },
    mileStones: {
      orderConfirmed: {
        estDate: "",
        actDate: "",
      },
      exFactory: {
        estDate: "",
        actDate: "",
      },
    },
    supplier: {
      supplierName: "",
      supplierAddress: "",
    },
    buyer: {
      buyerName: {},
      buyerAddress: "",
      finalDeliveryLocation: "",
    },
    orderDetails: {
      currencyOfPayment: {},
      weight: 0,
      volume: 0,
      containerMode: {},
      reqWork: "",
      serviceLevel: "",
      invoiceNumber: "",
      incoTerm: {},
      transportMode: "",
      reqStore: "",
      shipmentNumber: "",
      originCountry: "",
      goodsDescription: "",
      specialInstructions: "",
      // containerRequired: [
      //   {
      //     count: 0,
      //     type: "",
      //   },
      // ],
    },
  },
  reducers: {
    updateOrderSpecific: (state, action) => {
      state[action.payload.objName][action.payload.name] = action.payload.value;
    },
    emptyData: (state, action) => {
      // console.log("Data empty ho gaya, shayad.");
      state = null;
    },
    updateOrderSpecificContainer: (state, action) => {
      state.orderDetails.containerRequired[action.payload.index][
        action.payload.name
      ] = action.payload.value;
    },
    addContainer: (state, action) => {
      const tempObj = { count: 0, type: "" };
      state.orderDetails.containerRequired.push(tempObj);
    },
    removeContainer: (state, action) => {
      state.orderDetails.containerRequired.splice(action.payload.index, 1);
    },
  },
});
export const {
  updateOrderSpecific,
  addContainer,
  removeContainer,
  updateOrderSpecificContainer,
  emptyData,
} = orderSlice.actions;
export default orderSlice.reducer;
