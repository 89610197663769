import React from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const FilterModelTransaction = ({
  filterModel,
  setFilterModel,
  filter,
  setFilter,
  getTransactionCollection,
  filterObj,
}) => {
  const handleClose = () => setFilterModel(false);
  return (
    <Dialog
      open={filterModel}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent>
        <Card style={{ border: "none", boxShadow: "none" }}>
          <CardHeader
            action={
              <Button
                className="font-change"
                onClick={() => {
                  filterObj.current = {
                    Org: "",
                    InvoiceNo: "",
                    ShipmentNo: "",
                    PoNo: "",
                    BlNo: "",
                    fromDueDate: null,
                    toDueDate: null,
                    fromInvoideDate: null,
                    toInvoiceDate: null,
                    InvoiceCurrency: "",
                  };
                  setFilter({
                    Org: "",
                    InvoiceNo: "",
                    ShipmentNo: "",
                    PoNo: "",
                    BlNo: "",
                    fromDueDate: null,
                    toDueDate: null,
                    fromInvoideDate: null,
                    toInvoiceDate: null,
                    InvoiceCurrency: "",
                  });
                }}
              >
                Reset all
              </Button>
            }
            subheader={
              <Button
                className="font-change"
                color={"primary"}
                onClick={() => {
                  setFilterModel(false);
                  const clearObj = {
                    Org: "",
                    InvoiceNo: "",
                    ShipmentNo: "",
                    PoNo: "",
                    BlNo: "",
                    fromDueDate: null,
                    toDueDate: null,
                    fromInvoideDate: null,
                    toInvoiceDate: null,
                    InvoiceCurrency: "",
                  };
                  filterObj.current = { ...clearObj };
                  setFilter(clearObj);
                  getTransactionCollection(0, filter, "");
                }}
              >
                <KeyboardBackspaceIcon /> Back
              </Button>
            }
          />
          <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">Client</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.Org}
                        placeholder="Please enter organization name"
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilter((prev) => {
                              return { ...prev, Org: e.target.value };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">Invoice No.</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.InvoiceNo}
                        placeholder="Please enter invoice no."
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilter((prev) => {
                              return { ...prev, InvoiceNo: e.target.value };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">Job No.</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.ShipmentNo}
                        placeholder="Please enter Job No."
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilter((prev) => {
                              return { ...prev, ShipmentNo: e.target.value };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">PO No.</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.PoNo}
                        placeholder="Please enter PO No."
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilter((prev) => {
                              return { ...prev, PoNo: e.target.value };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">
                      House Bill No.
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.BlNo}
                        placeholder="Please enter House Bill No."
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilter((prev) => {
                              return { ...prev, BlNo: e.target.value };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">
                      Invoice Currency
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.InvoiceCurrency}
                        placeholder="Please enter currency"
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilter((prev) => {
                              return {
                                ...prev,
                                InvoiceCurrency: e.target.value,
                              };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Typography className="font-change">Due Date</Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 datepicker"
                        label="Enter Date"
                        inputFormat="DD/MM/YYYY"
                        value={filter.fromDueDate}
                        maxDate={filter?.toDueDate}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            fromDueDate: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={1} md={12} sm={12} xs={12}>
                    <Typography className="font-change" align="center">
                      to
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 datepicker"
                        inputFormat="DD/MM/YYYY"
                        label="Enter Date"
                        value={filter.toDueDate}
                        minDate={filter?.fromDueDate}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            toDueDate: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Typography className="font-change">
                      Invoice Date
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 datepicker"
                        label="Enter Date"
                        inputFormat="DD/MM/YYYY"
                        value={filter.fromInvoideDate}
                        maxDate={filter?.toDueDate}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            fromInvoideDate: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={1} md={12} sm={12} xs={12}>
                    <Typography className="font-change" align="center">
                      to
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 datepicker"
                        label="Enter Date"
                        inputFormat="DD/MM/YYYY"
                        value={filter.toInvoiceDate}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            toInvoiceDate: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className="d-flex justify-content-end">
            <Button
              className="font-change"
              onClick={() => {
                filterObj.current = { ...filter };
                getTransactionCollection(0, filter, "");
                setFilterModel(false);
              }}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default FilterModelTransaction;
