import {
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import BarChart from "../../../../charts/ShipmentAnalysis/BarChart";
import DelayedPieChart from "../../../../charts/ShipmentAnalysis/DelayedPieChart";
import HalfPieChart from "../../../../charts/ShipmentAnalysis/HalfPieChart";
import MapChart from "../../../../charts/ShipmentAnalysis/MapChart";
// import TannagePieChart from "../../../../charts/CarrierAnalysis/TannagePieChart";
import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import CloseIcon from "@mui/icons-material/Close";
import TannagePie from "../../../../charts/ShipmentAnalysis/TannagePie";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Fade from "@mui/material/Fade";
import CustomTooltip from "../../../../utils/CustomTooltip";
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 26,
    },
    "&:before": {
      left: 12,
    },
    "&:after": {
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function ShipmentAnalysis(props) {
  let { filterOptions } = props;

  let handleClickOpen = props.handleClickOpen;
  const [sectorwiseDirection, setSectorwiseDirection] = useState("IMP");
  const [checkedSectorWise, setCheckedSectorWise] = useState(false);
  const [loadingobj, setLoadingobj] = useState({
    barChart: false,
    delayedPieChart: false,
    halfPieChart: false,
    tannagePie: false,
    mapChart: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tanType, setTanType] = useState("TEU");

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setUnits = (param) => {
    setTanType(param);
    handleClose();
  };
  // TEU should not be selected if the MODE == AIR
  useEffect(() => {
    if (filterOptions.filterMode === "AIR" && tanType === "TEU") {
      setTanType("VOL");
    }
  }, [filterOptions]);

  return (
    <Container
      maxWidth="xxl"
      sx={{
        backgroundColor: "var(--color-white)",
        borderRadius: "5px",
      }}
    >
      <Typography variant="h6" className="font-shipment">
        Shipment Analysis
      </Typography>
      <Divider />
      <Grid container spacing={0}>
        {/* Charts */}
        <Grid
          item
          lg={12}
          sx={{
            marginTop: "1rem",
          }}
        >
          <Grid container columnSpacing={1}>
            {/* Other charts */}
            <Grid item lg={8} xs={12}>
              <Grid container rowSpacing={3} columnSpacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="paper-chart">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <Typography
                        variant="subtitle2"
                        className="font-shipment"
                        color={"primary"}
                      >
                        No. of shipment mode wise{" "}
                        <CustomTooltip
                          of="Shipment_Analytics"
                          forKey="Mode_wise"
                        />
                      </Typography>

                      {loadingobj.barChart ? (
                        <CircularProgress size={"1.8rem"} />
                      ) : (
                        <IconButton size={"small"}>
                          <ZoomOutMapIcon
                            onClick={() => {
                              handleClickOpen(
                                "No. of shipment mode wise",
                                <BarChart
                                  shipmentpopup={true}
                                  filterOptions={filterOptions}
                                  setLoadingobj={setLoadingobj}
                                  loadingobj={loadingobj}
                                />
                              );
                            }}
                          />
                        </IconButton>
                      )}
                    </div>

                    <BarChart
                      shipmentpopup={false}
                      filterOptions={filterOptions}
                      setLoadingobj={setLoadingobj}
                      loadingobj={loadingobj}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="paper-chart">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <Typography
                        variant="subtitle2"
                        color={"primary"}
                        className="font-shipment"
                      >
                        Shipment Delivery Status{" "}
                        <CustomTooltip
                          of="Shipment_Analytics"
                          forKey="Delivery_Status"
                        />
                      </Typography>
                      <IconButton size={"small"}>
                        <ZoomOutMapIcon
                          onClick={() => {
                            handleClickOpen(
                              " Delayed Shipment",
                              <DelayedPieChart
                                filterOptions={filterOptions}
                                setLoadingobj={setLoadingobj}
                                loadingobj={loadingobj}
                              />
                            );
                          }}
                        />
                      </IconButton>
                    </div>

                    <DelayedPieChart
                      filterOptions={filterOptions}
                      setLoadingobj={setLoadingobj}
                      loadingobj={loadingobj}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="paper-chart">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <Typography
                        variant="subtitle2"
                        color={"primary"}
                        className="font-shipment"
                      >
                        Shipment : Collected / PrePaid{" "}
                        <CustomTooltip
                          of="Shipment_Analytics"
                          forKey="Collected_PrePaid"
                        />
                      </Typography>
                      <IconButton size={"small"}>
                        <ZoomOutMapIcon
                          onClick={() => {
                            handleClickOpen(
                              "Shipment : Collected / PrePaid",
                              <HalfPieChart
                                filterOptions={filterOptions}
                                setLoadingobj={setLoadingobj}
                                loadingobj={loadingobj}
                              />
                            );
                          }}
                        />
                      </IconButton>
                    </div>

                    <HalfPieChart
                      filterOptions={filterOptions}
                      setLoadingobj={setLoadingobj}
                      loadingobj={loadingobj}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="paper-chart">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <Typography
                        variant="subtitle2"
                        color={"primary"}
                        className="font-shipment"
                      >
                        Tonnage{" "}
                        <CustomTooltip
                          of="Shipment_Analytics"
                          forKey="Tonnage"
                        />
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          label={tanType == "" ? "CHW" : tanType}
                          size="small"
                          color="primary"
                        />
                        <IconButton size={"small"} onClick={handleClick}>
                          <FilterAltIcon />
                        </IconButton>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            "aria-labelledby": "fade-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          {/* TEU should not be selected if the MODE == AIR */}
                          {filterOptions.filterMode !== "AIR" && (
                            <MenuItem
                              onClick={() => setUnits("TEU")}
                              selected={tanType == "TEU"}
                            >
                              TEU
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => setUnits("VOL")}
                            selected={tanType == "VOL"}
                          >
                            VOL
                          </MenuItem>
                          <MenuItem
                            onClick={() => setUnits("")}
                            selected={tanType == ""}
                          >
                            CHW
                          </MenuItem>
                        </Menu>

                        <IconButton size={"small"}>
                          <ZoomOutMapIcon
                            onClick={() => {
                              handleClickOpen(
                                "Tonnage",
                                <TannagePie
                                  filterOptions={filterOptions}
                                  setLoadingobj={setLoadingobj}
                                  loadingobj={loadingobj}
                                  tanType={tanType}
                                />
                              );
                            }}
                          />
                        </IconButton>
                      </div>
                    </div>

                    <TannagePie
                      filterOptions={filterOptions}
                      setLoadingobj={setLoadingobj}
                      loadingobj={loadingobj}
                      tanType={tanType}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* Map chart */}
            <Grid item lg={4} md={12} xs={12}>
              <Grid container spacing={0}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="paper-chart-map">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <Typography
                        variant="subtitle2"
                        color={"primary"}
                        className="font-shipment"
                      >
                        No. of shipment Sector wise{" "}
                        <CustomTooltip
                          of="Shipment_Analytics"
                          forKey="Shipment_Sector_wise"
                        />
                      </Typography>

                      <Stack direction="row" spacing={0} alignItems="center">
                        IMP
                        <Android12Switch
                          onChange={(e) => {
                            setCheckedSectorWise(e.target.checked);
                            if (e.target.checked) {
                              setSectorwiseDirection("EXP");
                            } else {
                              setSectorwiseDirection("IMP");
                            }
                          }}
                        />
                        EXP
                      </Stack>
                    </div>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      alignItems="center"
                    ></Stack>
                    <MapChart
                      sectorwiseDirection={sectorwiseDirection}
                      filterOptions={filterOptions}
                      setLoadingobj={setLoadingobj}
                      loadingobj={loadingobj}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
