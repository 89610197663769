import React, { useState, useContext } from "react";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import IconButton from "@mui/material/IconButton";
import { basename } from "../../../Config";
import { DateFormat } from "../../../utils/moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { genrateDownloadLink } from "../../../utils/utility";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../store/context/AuthProvider";
import { toast } from "react-hot-toast";
import "../statement.css";
import CustomTooltip from "../../../utils/CustomTooltip";
const Tableview = ({ data, TransactionCount }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isloading, setisLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const donloadShip = (obj) => {
    setisLoading(true);

    instance
      .post(API_PATH.DOWNLOAD_DATA, obj)
      .then((res) => {
        console.log("Doc download", res);
        const downloadLink = document.createElement("a");
        const prefix = genrateDownloadLink(
          res.data[0].DocumentModelCollection[0].SC_FileName ?? ""
        );
        downloadLink.href = `${prefix}${
          res.data[0].DocumentModelCollection[0].ImageDataVC ?? ""
        }`;
        if (prefix && res.data[0].DocumentModelCollection[0].ImageDataVC) {
          downloadLink.download =
            res.data[0].DocumentModelCollection[0].SC_FileName ?? "";
          downloadLink.click();
        } else {
          toast.error("Invalid document found!");
        }
        setisLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        console.log(err);
        toast.error("No document found!");
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="container-fluid desk-sip-table">
      <div className="row justify-content-between tableRow-div sip-desk-tab">
        <div className="col-1 font-shipment">Invoice No.</div>
        <div className="col-1 font-shipment">Job No.</div>
        <div className="col-1 font-shipment"> PO/Order Ref No.</div>
        <div className="col-1 font-shipment">House Bill No.</div>
        <div className="col-1 font-shipment">Client </div>
        <div className="col-1 font-shipment">Due Date</div>
        <div className="col-1 font-shipment">Invoice Date</div>
        <div className="col-1 font-shipment">
          Invoice Amount
          <CustomTooltip of="Statement_Of_Account" forKey="Invoice_Amount" />
        </div>
        <div className="col-1 font-shipment">
          <div>
            Outstanding <br /> Amount
            <CustomTooltip
              of="Statement_Of_Account"
              forKey="Outstanding_Amount"
            />
          </div>
        </div>
        <div className="col-1 font-shipment">
          <div>
            Aging <CustomTooltip of="Statement_Of_Account" forKey="Aging" />
          </div>
        </div>
        <div className="col-1 font-shipment">Download invoice</div>
      </div>
      {TransactionCount === 0 ? (
        <div className="d-flex justify-content-center align-items-center font-change">
          No data found!
        </div>
      ) : (
        <>
          {" "}
          {data.map((item, index) => {
            return (
              <div
                className="row justify-content-between tableBody-div"
                key={index}
              >
                <div
                  className="col-1 wordWrap  font-shipment"
                  title={item?.TransactionNumber ?? ""}
                >
                  {item?.TransactionNumber ?? ""}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={item?.JobNumber ?? ""}
                  onClick={() => {
                    if (item?.JobNumber.length > 0) {
                      context.setShipmentId(data?.ShipmentId);

                      const path = `${Routeconstant.SHIPMENT_LISTING}/${Routeconstant.DETAILS}`;
                      navigate(path, {
                        state: {
                          ShipmentId: item.JobNumber,
                          data: item,
                          HouseBillNumber: item.HBLNumber,
                        },
                      });
                      context.setGridViewShipment(false);
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    color: "var(--color-secondary-dark)",
                  }}
                >
                  {item?.JobNumber ?? ""}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={item?.PORefNumber ?? ""}
                >
                  {item?.PORefNumber ?? ""}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={item?.HBLNumber ?? ""}
                >
                  {item?.HBLNumber ?? ""}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={item?.BranchOrganizationName ?? ""}
                >
                  {item?.BranchOrganizationName ?? ""}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={DateFormat(item?.DueDate ?? null)}
                >
                  {DateFormat(item?.DueDate ?? null)}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={DateFormat(item?.TransactionDate ?? null)}
                >
                  {DateFormat(item?.TransactionDate ?? null)}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={`${item?.InvoiceCurrency ?? ""}${" "}${
                    item?.InvoiceAmount ?? ""
                  }`}
                >
                  {`${item?.InvoiceCurrency ?? ""}${" "}${
                    item?.InvoiceAmount ?? ""
                  }`}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={item?.CalculatedOutstandingAmount ?? ""}
                >
                  {item?.CalculatedOutstandingAmount ?? ""}
                </div>
                <div
                  className="col-1 wordWrap font-shipment"
                  title={item?.Aging ?? ""}
                >
                  {item?.Aging ?? ""}
                </div>
                <div className="col-1 wordWrap d-flex justify-content-center align-items-center font-shipment">
                  <IconButton
                    onClick={() => {
                      let obj = {
                        InvoiceNumber: item.TransactionNumber,
                        TransactionType: item.TransactionType,
                        Ledger: item.Ledger,
                        CompanyCode: item.CompanyCode,
                      };
                      setLoadingIndex(index);
                      donloadShip(obj);
                    }}
                    disabled={isloading}
                  >
                    <DownloadForOfflineOutlinedIcon
                      color={isloading ? "" : "ternary"}
                    />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </>
      )}

      {/*--Mobile Respnsive */}
      {TransactionCount === 0 ? (
        <div></div>
      ) : (
        <>
          {data.map((item, index) => {
            return (
              <div class="row" key={index}>
                <div class="card sip-card">
                  <div class="card-body">
                    <div className="border-bottom-sip  d-flex justify-content-between align-items-center">
                      <div>{item?.TransactionNumber ?? ""}</div>
                      <div>
                        <IconButton
                          onClick={() => {
                            let obj = {
                              InvoiceNumber: item.TransactionNumber,
                              TransactionType: item.TransactionType,
                              Ledger: item.Ledger,
                              CompanyCode: item.CompanyCode,
                            };
                            setLoadingIndex(index);
                            donloadShip(obj);
                          }}
                          disabled={isloading}
                        >
                          <DownloadForOfflineOutlinedIcon
                            color={isloading ? "" : "primary"}
                          />
                        </IconButton>
                      </div>
                    </div>

                    <div className="row p-2 align-items-center">
                      <div className="col-9" style={{ lineHeight: "10px" }}>
                        <ul className="list-inline" style={{ display: "flex" }}>
                          <li>Job No:</li>
                          <li
                            className="ps-2"
                            onClick={() => {
                              if (item?.JobNumber.length > 0) {
                                const path = `${Routeconstant.SHIPMENT_LISTING}/${Routeconstant.DETAILS}`;
                                navigate(path, {
                                  state: {
                                    ShipmentId: item.JobNumber,
                                  },
                                });
                                context.setGridViewShipment(false);
                              }
                            }}
                          >
                            {item?.JobNumber ?? ""}
                          </li>
                        </ul>
                        <ul className="list-inline" style={{ display: "flex" }}>
                          <li>PO No.</li>
                          <li className="ps-2"> {item?.PORefNumber ?? ""}</li>
                        </ul>
                        <ul className="list-inline" style={{ display: "flex" }}>
                          <li>House Bill No:</li>
                          <li className="ps-2"> {item?.HBLNumber ?? ""}</li>
                        </ul>
                        <ul className="list-inline" style={{ display: "flex" }}>
                          <li>Client</li>
                          <li className="ps-2">
                            {" "}
                            {item?.BranchOrganizationName ?? ""}
                          </li>
                        </ul>
                        <ul className="list-inline" style={{ display: "flex" }}>
                          <li>Due Date</li>
                          <li className="ps-2">
                            {" "}
                            {DateFormat(item?.DueDate ?? null)}
                          </li>
                        </ul>
                        <ul className="list-inline" style={{ display: "flex" }}>
                          <li>Invoice Date</li>
                          <li className="ps-2">
                            {" "}
                            {DateFormat(item?.TransactionDate ?? null)}
                          </li>
                        </ul>
                        <ul className="list-inline" style={{ display: "flex" }}>
                          <li>Outstanding Amount</li>
                          <li className="ps-2">
                            {" "}
                            {item?.CalculatedOutstandingAmount ?? ""}
                          </li>
                        </ul>
                      </div>

                      <div className="col-3 border-left-sip text-center">
                        <h6>Aging</h6>
                        <h4> {item?.Aging ?? ""}</h4>
                      </div>
                    </div>
                    <div className="row  border-top-sip ">
                      <div class="col-7">
                        <p>
                          Invoice Amount{" "}
                          <CustomTooltip
                            of="Statement_Of_Account"
                            forKey="Invoice_Amount"
                          />
                        </p>
                      </div>
                      <div class="col-5  text-right">
                        <b>{`${item?.InvoiceCurrency ?? ""}${" "}${
                          item?.InvoiceAmount ?? ""
                        }`}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Tableview;
