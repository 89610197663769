import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { basename, customerName } from "../../Config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DetailsTypo from "../../components/typograpy/detailstypo";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tooltip from "@mui/material/Tooltip";
import Mapcomp from "./Mapcomp";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import { useNavigate } from "react-router-dom";
import { API_PATH } from "../../constant/apiPath";
import instance from "../../api/Interceptor";
import { DateFormat } from "../../utils/moment";
import { Skeleton } from "@mui/material";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import "./index.css";
import moment from "moment/moment";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import urlResolver from "../../utils/urlResolver";

export default function ShipmentDetailsPublic() {
  const navigate = useNavigate();
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const [curvy, setCurvy] = useState(false);
  //let array = id.split(":id");
  let shipmentId = id;
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [cw1Milestone, setCw1Milestone] = useState({});
  const [mapData, setMapData] = useState({});
  const [emailContact, setemailContact] = useState("abhishek@sflhub.com");
  const [whatsappNumber, setWhatsappNumber] = useState("8607788515");
  const [accordin, setAccordin] = useState({
    first: true,
    second: true,
    third: true,
  });
  const [cw1ProgressPercentage, setCw1ProgressPercentage] = useState(null);
  const calculatePercentage = () => {
    let completedMilestones = cw1Milestone?.ShipMilestoneCollection?.filter(
      (x) => x.ActualStartDate !== null
    ).length;
    let percentage = Math.round(
      (completedMilestones / cw1Milestone?.ShipMilestoneCollection?.length) *
        100
    );
    setCw1ProgressPercentage(percentage);
  };
  useEffect(() => {
    if (cw1Milestone?.ShipMilestoneCollection) {
      calculatePercentage();
    }
  }, [cw1Milestone]);
  const getShipmentWithoutLogin = () => {
    setLoading(true);
    const body = {
      ShipmentId: shipmentId,
      TransportMode: "",
      // "ShowDeveloperResponse":true
    };
    instance
      .post(API_PATH.GET_SHIPMENT_WITHOUT_LOGIN, body)
      .then((res) => {
        setLoading(false);
        if (res?.data[0]?.shipmentcollection[0]?.TransportMode === "AIR")
          setCurvy(true);
        setShipmentDetails(res?.data[0]?.shipmentcollection[0]);
        setCw1Milestone(res?.data[0]?.milestonecollection[0]);
        setMapData(res?.data[0]?.searoutcollection[0]);

        if (res?.message?.Message === "Data Not Found") {
          setNoDataFound(res?.message?.Message);
        } else {
          setNoDataFound(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "error");
      });
  };

  useEffect(() => {
    getShipmentWithoutLogin();
  }, [shipmentId]);
  // const sendMail = () => {
  //   let body = `Hello, %0A%0ATeam ${customerName}, %0A%0ACould you please send more details about my shipment? %0A%0AShipment Number: ${
  //     shipmentDetails?.ShipmentId ?? ""
  //   } %0AHouse BL Number: ${
  //     shipmentDetails?.HouseBillNumber ?? ""
  //   } %0ATransport Mode: ${
  //     shipmentDetails?.ShipmentTransportMode ?? ""
  //   } %0A%0AThank you! %0A%0A`;
  //   window.location.href = `mailto:${emailContact}?subject=Shipment ID - ${
  //     shipmentDetails?.ShipmentId ?? ""
  //   }&body=${body}`;
  // };
  const Load = () => {
    if (loading) {
      return (
        <div
          className="skeleton-div"
          style={{ height: "29rem", width: "100%" }}
        >
          <Skeleton
            style={{ height: "45rem", marginTop: "1rem", width: "100%" }}
          />
        </div>
      );
    } else if (!noDataFound) {
      return (
        <>
          {" "}
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              marginLeft: "0.8rem",
            }}
          >
            <p
              className="p-0 num-data m-0"
              style={{ fontSize: "var(--fs-sublabel)" }}
            >
              {shipmentDetails?.ShipmentId}
            </p>
          </Grid>
          <Grid item lg={4.5}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                // justifyContent: "center",
                paddingTop: "2%",
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocationOnIcon />
                <DetailsTypo>
                  {" "}
                  Origin :{shipmentDetails?.OriginPortName}
                </DetailsTypo>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                }}
              >
                <LocationOnIcon />
                <DetailsTypo>
                  {" "}
                  Destination : {shipmentDetails?.DestinationPortName}
                </DetailsTypo>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4.5}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                // justifyContent: "center",
                paddingTop: "2%",
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                }}
              >
                <CalendarMonthIcon />
                <DetailsTypo>
                  {" "}
                  ETD : {DateFormat(shipmentDetails?.ETDDate ?? null)}
                </DetailsTypo>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                }}
              >
                <CalendarMonthIcon />
                <DetailsTypo>
                  {" "}
                  ETA : {DateFormat(shipmentDetails?.ETADate ?? null)}
                </DetailsTypo>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            xs={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              minWidth: "8%",
              gap: "1em",
            }}
          >
            {/* <Tooltip title="Mail">
              <MailIcon className="layout-notification" onClick={sendMail} />
            </Tooltip> */}
            {/* </a> */}
            {/* <Tooltip title="Whatsapp">
              <a
                href={`https://web.whatsapp.com/send?phone=${whatsappNumber}`}
                target="_blank"
              >
                <WhatsAppIcon className="layout-notification" />
              </a>
            </Tooltip> */}
          </Grid>
          {/* Bottom */}
          <Grid item lg={12} md={12} sm={12}>
            <Paper elevation={0}>
              <Grid container spacing={0}>
                {/* SHIPMENT SPECIFICS */}
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    marginTop: "1rem",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0% 2%",
                    }}
                  >
                    <p
                      style={{
                        minWidth: "fit-content",
                      }}
                      className="m-0 basic-specific"
                    >
                      Shipment Specifics
                    </p>
                    <div
                      style={{
                        border: "1px solid var(--color-secondary-light) ",
                        width: "90%",
                        opacity: "0.4",
                        marginLeft: "20px",
                      }}
                    />

                    <IconButton
                      onClick={(e) => {
                        if (accordin.first === true) {
                          setAccordin({ ...accordin, first: false });
                        } else {
                          setAccordin({ ...accordin, first: true });
                        }
                      }}
                    >
                      <ExpandCircleDownOutlinedIcon color="primary" />
                    </IconButton>
                  </Grid>

                  {accordin.first ? (
                    <>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          paddingLeft: "2%",
                          paddingBottom: "1rem",
                        }}
                      >
                        <Grid item lg={2} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0  text-head">Master Bill No.</p>
                            </Grid>
                            {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                              {shipmentDetails?.MasterBillNumber &&
                                shipmentDetails?.MasterBillNumber?.map(
                                  (i, index) => {
                                    return (
                                      <p key={index} className="m-0 text-sub">
                                        {i ?? "N/A"}
                                      </p>
                                    );
                                  }
                                )}
                            </Grid> */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              {shipmentDetails &&
                                shipmentDetails?.MasterBillNumber && (
                                  <p
                                    className="m-0 text-sub"
                                    title={shipmentDetails?.MasterBillNumber}
                                  >
                                    {shipmentDetails?.MasterBillNumber[0] ??
                                      null}{" "}
                                    <span>
                                      {shipmentDetails?.MasterBillNumber
                                        .length > 1
                                        ? "..."
                                        : ""}
                                    </span>
                                  </p>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head ">House Bill No.</p>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-sub">
                                {shipmentDetails?.HouseBillNumber ?? "N/A"}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head ">
                                Order Reference No.
                              </p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-sub">
                                {shipmentDetails?.OrderRef ?? "N/A"}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head">Type of Shipment</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-sub">
                                {shipmentDetails?.ShipmentContainerMode ??
                                  shipmentDetails?.ContainerMode ??
                                  null}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <p className="m-0 text-head ">INCO Terms</p>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p className="m-0 text-sub">
                              {shipmentDetails?.INCOTerm ?? "N/A"}
                            </p>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <p className="m-0 text-head ">Shipper Reference</p>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p className="m-0 text-sub">
                              {shipmentDetails?.ShipperRef ?? "N/A"}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}

                  {/* --Container and cargo description-- */}
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      padding: "0% 2%",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          minWidth: "fit-content",
                        }}
                        className="m-0 basic-specific"
                      >
                        Container and cargo description
                      </p>

                      <div
                        style={{
                          border: "1px solid var(--color-secondary-light) ",
                          width: "90%",
                          opacity: "0.4",
                          marginLeft: "20px",
                        }}
                      />

                      <IconButton>
                        <ExpandCircleDownOutlinedIcon
                          color="primary"
                          onClick={() => {
                            if (accordin.second === true) {
                              setAccordin({ ...accordin, second: false });
                            } else {
                              setAccordin({ ...accordin, second: true });
                            }
                          }}
                        />
                      </IconButton>
                    </Grid>

                    {accordin.second ? (
                      <>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            paddingLeft: "1%",
                            paddingBottom: "1rem",
                          }}
                        >
                          <Grid item lg={2} md={4} sm={4} xs={6}>
                            <Grid container spaing={0}>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <p className="m-0 text-head "> Carrier</p>
                              </Grid>
                              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                <p
                                  className="m-0 text-sub"
                                  title={shipmentDetails?.CarrierName ?? "N/A"}
                                >
                                  {shipmentDetails?.CarrierName[0] ?? "N/A"}
                                </p>
                              </Grid> */}
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                {shipmentDetails &&
                                  shipmentDetails?.CarrierName && (
                                    <p
                                      className="m-0 text-sub"
                                      title={shipmentDetails?.CarrierName}
                                    >
                                      {shipmentDetails?.CarrierName[0] ?? null}{" "}
                                      <span>
                                        {shipmentDetails?.CarrierName.length > 1
                                          ? "..."
                                          : ""}
                                      </span>
                                    </p>
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={2} md={4} sm={4} xs={6}>
                            <Grid container spaing={0}>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <p className="m-0 text-head">Container No.</p>
                              </Grid>

                              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                {shipmentDetails?.ContainerNumbers &&
                                  shipmentDetails?.ContainerNumbers?.map(
                                    (i, index) => {
                                      return (
                                        <p
                                          key={index}
                                          className="m-0 text-sub"
                                          title={i ?? "N/A"}
                                        >
                                          {i ?? "N/A"}
                                          {index ===
                                          shipmentDetails.ContainerNumbers
                                            .length -
                                            1
                                            ? ""
                                            : ","}
                                        </p>
                                      );
                                    }
                                  )}
                                {/* <p
                          className="m-0 text-sub  wordWrap"
                          title={shipmentDetails?.ContainerNumbers ?? "N/A"}
                        >
                          {shipmentDetails?.ContainerNumbers ?? "N/A"}
                        </p> */}
                              {/* </Grid> */}
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                {shipmentDetails &&
                                  shipmentDetails?.ContainerNumbers && (
                                    <p
                                      className="m-0 text-sub"
                                      title={shipmentDetails.ContainerNumbers}
                                    >
                                      {shipmentDetails.ContainerNumbers[0] ??
                                        null}{" "}
                                      <span>
                                        {shipmentDetails.ContainerNumbers
                                          .length > 1
                                          ? "..."
                                          : ""}
                                      </span>
                                    </p>
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={2} md={4} sm={4} xs={6}>
                            <Grid container spaing={0}>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <p className="m-0 text-head ">
                                  Container Count
                                </p>
                              </Grid>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <p
                                  className="m-0 text-sub"
                                  title={
                                    shipmentDetails?.FCLContainerCount ?? "N/A"
                                  }
                                >
                                  {shipmentDetails?.FCLContainerCount ?? "N/A"}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={2} md={4} sm={4} xs={6}>
                            <Grid container spaing={0}>
                              <p className="m-0 text-head">Type</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub"
                                title={shipmentDetails?.ReleaseType ?? "N/A"}
                              >
                                {shipmentDetails?.ReleaseType ?? "N/A"}
                              </p>
                            </Grid>
                          </Grid>
                          <Grid item lg={2} md={4} sm={4} xs={6}>
                            <Grid container spaing={0}>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <p className="m-0 text-head ">Weight</p>
                              </Grid>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <p
                                  className="m-0 text-sub"
                                  title={shipmentDetails?.TotalWeight ?? "N/A"}
                                >
                                  {shipmentDetails?.TotalWeight ?? "N/A"}
                                  {shipmentDetails?.TotalWeightUQ ?? "N/A"}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={2} md={4} sm={4} xs={6}>
                            <Grid container spaing={0}>
                              <p className="m-0 text-head">Volume</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub"
                                title={shipmentDetails?.TotalVolume ?? "N/A"}
                              >
                                {shipmentDetails?.TotalVolume ?? "N/A"}
                                {shipmentDetails?.TotalVolumeUQ ?? "N/A"}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>

                  {/* ---CW1 milestone---- */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0% 2%",
                    }}
                  >
                    <p
                      style={{
                        minWidth: "fit-content",
                      }}
                      className="m-0 basic-specific"
                    >
                      Milestone
                    </p>
                    <div
                      style={{
                        border: "1px solid var(--color-secondary-light) ",
                        width: "90%",
                        opacity: "0.4",
                        marginLeft: "20px",
                      }}
                    />

                    <IconButton
                      onClick={(e) => {
                        if (accordin.third === true) {
                          setAccordin({ ...accordin, third: false });
                        } else {
                          setAccordin({ ...accordin, third: true });
                        }
                      }}
                    >
                      <ExpandCircleDownOutlinedIcon color="primary" />
                    </IconButton>
                  </Grid>

                  {accordin.third ? (
                    <div style={{ padding: "0% 2%" }}>
                      {cw1Milestone?.ShipMilestoneCollection &&
                      !cw1Milestone?.ShipMilestoneCollection?.length &&
                      cw1Milestone?.ContMilestoneCollection &&
                      !cw1Milestone?.ContMilestoneCollection?.length &&
                      cw1Milestone?.ConMilestoneCollection &&
                      !cw1Milestone?.ConMilestoneCollection?.length ? (
                        <div className="d-flex justify-content-center">
                          <p className="m-0 font-change">No data found!</p>
                        </div>
                      ) : (
                        <>
                          <div className="cw1-card w-100 overflow-auto">
                            <div className="d-flex">
                              <div className="cw1-text mr-2">
                                <p className="m-0 cw1-data">
                                  {cw1Milestone?.ShipmentId}
                                </p>
                              </div>
                              <div className="d-flex">
                                <Grid
                                  container
                                  spacing={1}
                                  style={{ width: "100%" }}
                                >
                                  <Grid item lg={12}>
                                    <div className="d-flex">
                                      {cw1Milestone?.ShipMilestoneCollection?.map(
                                        (item) => {
                                          return (
                                            <Typography
                                              variant="caption"
                                              style={{
                                                marginRight: "8em",
                                                minWidth: "8em",
                                                maxWidth: "8em",
                                              }}
                                              color="primary"
                                            >
                                              {item?.ActualStartDate
                                                ? `A: ${moment(
                                                    item?.ActualStartDate
                                                  ).format("DD/MM/YYYY")}`
                                                : item?.EstimatedDate === null
                                                ? "E:"
                                                : `E: ${moment(
                                                    item?.EstimatedDate
                                                  ).format("DD/MM/YYYY")}`}
                                              {/* {DateFormat(
                                                item?.ActualStartDate  ?? 
                                              null
                                              )} */}
                                            </Typography>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item lg={12} sm={12} xs={12} md={12}>
                                    <LinearProgress
                                      variant="determinate"
                                      color="success"
                                      value={cw1ProgressPercentage - 5}
                                    />
                                  </Grid>
                                  <Grid item lg={12}>
                                    <div className="d-flex">
                                      {cw1Milestone?.ShipMilestoneCollection?.map(
                                        (item) => {
                                          return (
                                            <Typography
                                              variant="caption"
                                              style={{
                                                marginRight: "8em",
                                                minWidth: "8em",
                                                maxWidth: "8em",
                                              }}
                                            >
                                              {item.Description}
                                            </Typography>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            {cw1Milestone?.ConMilestoneCollection ? (
                              <>
                                {cw1Milestone?.ConMilestoneCollection.map(
                                  (item, index) => {
                                    return (
                                      <div className="d-flex" key={index}>
                                        <div className="cw1-text mr-2">
                                          <p className="m-0 cw1-data">
                                            {item?.ConsolId ?? "N/A"}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <Grid
                                            container
                                            spacing={1}
                                            style={{ width: "100%" }}
                                          >
                                            <Grid item lg={12}>
                                              <div className="d-flex">
                                                {item.MilestoneCollection.map(
                                                  (i, ind) => {
                                                    return (
                                                      <Typography
                                                        variant="caption"
                                                        style={{
                                                          marginRight: "8em",
                                                          minWidth: "8em",
                                                          maxWidth: "8em",
                                                        }}
                                                        color="primary"
                                                        key={ind}
                                                      >
                                                        {DateFormat(
                                                          i?.ActualStartDate ??
                                                            null
                                                        )}
                                                      </Typography>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={12}
                                              sm={12}
                                              xs={12}
                                              md={12}
                                            >
                                              <LinearProgress
                                                variant="determinate"
                                                color="success"
                                                value={
                                                  cw1ProgressPercentage - 5
                                                }
                                              />
                                            </Grid>
                                            <Grid item lg={12}>
                                              <div className="d-flex">
                                                {item.MilestoneCollection.map(
                                                  (i, ind) => {
                                                    return (
                                                      <Typography
                                                        variant="caption"
                                                        style={{
                                                          marginRight: "8em",
                                                          minWidth: "8em",
                                                          maxWidth: "8em",
                                                        }}
                                                        key={ind}
                                                      >
                                                        {i?.Description ??
                                                          "N/A"}
                                                      </Typography>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                            {cw1Milestone?.ContMilestoneCollection ? (
                              <>
                                {cw1Milestone?.ContMilestoneCollection.map(
                                  (item, index) => {
                                    return (
                                      <div className="d-flex" key={index}>
                                        <div className="cw1-text mr-2">
                                          <p className="m-0 cw1-data">
                                            {item?.Containernum ?? "N/A"}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <Grid
                                            container
                                            spacing={1}
                                            style={{ width: "100%" }}
                                          >
                                            <Grid item lg={12}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                {item.MilestoneCollection.map(
                                                  (i, ind) => {
                                                    return (
                                                      <Typography
                                                        variant="caption"
                                                        style={{
                                                          marginRight: "8em",
                                                          minWidth: "8em",
                                                          maxWidth: "8em",
                                                        }}
                                                        color="primary"
                                                        key={ind}
                                                      >
                                                        {DateFormat(
                                                          i?.ActualStartDate ??
                                                            null
                                                        )}
                                                      </Typography>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={12}
                                              sm={12}
                                              xs={12}
                                              md={12}
                                            >
                                              <LinearProgress
                                                variant="determinate"
                                                color="success"
                                                value={
                                                  cw1ProgressPercentage - 5
                                                }
                                              />
                                            </Grid>
                                            <Grid item lg={12}>
                                              <div className="d-flex">
                                                {item.MilestoneCollection.map(
                                                  (i, ind) => {
                                                    return (
                                                      <Typography
                                                        variant="caption"
                                                        style={{
                                                          marginRight: "8em",
                                                          minWidth: "8em",
                                                          maxWidth: "8em",
                                                        }}
                                                        key={ind}
                                                      >
                                                        {i?.Description ??
                                                          "N/A"}
                                                      </Typography>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                </Grid>
                {/* MAP
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{
                    width: "37rem",
                    height: "30rem",
                    position: "relative",
                  }}
                >
                  <Mapcomp
                    setCurvy={setCurvy}
                    curvy={curvy}
                    setMapData={setMapData}
                    mapData={mapData}
                  />
                </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </>
      );
    } else {
      return (
        <div
          style={{
            height: "70vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>No data found</h5>
        </div>
      );
    }
  };

  // }
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          marginBottom: "1%",
        }}
      >
        <AppBar position="static" color="transparent">
          <Toolbar>
            <img src={urlResolver("assets/header_icon.svg")} />

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <a href={`https://www.bdginternational.com/`} target="_blank">
                <IconButton
                  onClick={() => {
                    // navigate("https://softfreightlogic.com/");
                  }}
                >
                  <img
                    src={urlResolver("assets/headerLogo2.svg")}
                    style={{ height: "3.2rem" }}
                  />
                </IconButton>
              </a>
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Container
        maxWidth={"xxl"}
        style={{
          maxHeight: "20vh !important",
          padding: "1%",
          // margin: "1% 1% 1% 1%",
          background: "var(--color-miscellaneous-5)",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                navigate(`${Routeconstant.LOGIN}`);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <DetailsTypo>Back to Log in</DetailsTypo>
          </Grid>

          <Load />
        </Grid>
      </Container>
    </div>
  );
}
