import { styled, Typography } from "@mui/material";
import React from "react";
const LTypography = styled(Typography)(({ theme }) => ({
  //   fontSize: "2rem",
}));
export default function LightTypography(props) {
  return (
    <LTypography
      align={props?.align ? props.align : ""}
      variant={props?.variant ? props.variant : ""}
      color="#5B5675"
      style={{ fontSize: "var(--fs-subtitle)" }}
    >
      {props.children}
    </LTypography>
  );
}
