import { createSlice } from "@reduxjs/toolkit";
const inputSlice = createSlice({
  name: "inputData",
  initialState: {
    currencyOfPayment: [],
    containerMode: [],
    transportMode: [],
    incoTerm: [],
    countries: [],
    agentList: [],
    buyerList: [],
    packageList: [],
    supplierList: [],
    serviceLevel: [],
    buyerAddress: [],
    supplierAddress: [],
    docTypeList: [],
    weightUnitList: [],
    volumeUnitList: [],
    dimensionsUnitList: [],
    originPorts: [],
    destinationPorts: [],
    carriercodes: [],
  },
  reducers: {
    updateInputState: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
  },
});
export const { updateInputState } = inputSlice.actions;
export default inputSlice.reducer;
