import React, { useEffect, useState } from "react";
import "../User.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import { DateFormat } from "../../../utils/moment";
import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { styled } from "@mui/material/styles";
import { changeStatusApiCall } from "../userApi/userApiDefination";
const UserTableRow = ({
  setOpen,
  data,
  item,
  index,
  sendResetLink,
  setLoadingReset,
  loadingReset,
  setModuleCaller,
}) => {
  const [switchStatus, setSwitchStatus] = useState(null);
  useEffect(()=>{
    setSwitchStatus(item?.IsLoginEnabled)
  },[item])
  const [PauseTimmer, setPauseTimmer] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#65C466" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#a19f9c" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <tr>
      <td className="m-0 usercol_data " title={item?.ContactName ?? "N/A"}>
        <span> {item?.ContactName ?? "N/A"}</span>
      </td>
      <td className="m-0 usercol_data" title={item?.Email ?? "N/A"}>
        <span>{item?.Email ?? "N/A"}</span>
      </td>
      {item.OrgnisationCollection.length ? (
        <td className="m-0 usercol_data_orgnisation">
          <Tooltip
            title={item.OrgnisationCollection.map((i, ind) => {
              return (
                <Typography>
                  {i.OrgFullName}
                  <br />
                </Typography>
              );
            })}
          >
            <span>{item?.OrgnisationCollection[0]?.OrgFullName}</span>
          </Tooltip>
        </td>
      ) : (
        <>{"N/A"}</>
      )}
      <td className="m-0 usercol_data">
        <span>{DateFormat(item?.LastLoginUtcDate) ?? "N/A"}</span>
      </td>
      <td
        className="m-0 usercol_data"
        title={DateFormat(item.RegisteredDate ?? null)}
      >
        <span>{DateFormat(item.RegisteredDate ?? null)}</span>
      </td>
      <td
        className="m-0 usercol_data d-flex align-items-center  gap-2 "
        style={{ borderBottomWidth: "0px", borderTop: "0px" }}
        title={item?.Roles ?? "N/A"}
      >
        {data.map((Role) => {
          if (Role.length > 0) return <div className="admin-color">{Role}</div>;
        })}
      </td>

      <td className="user_btnn">
        <div
          style={{
            marginLeft: "35px",
            color: "var(--color-secondary-dark)",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <CustomSwitch
            checked={switchStatus}
            disabled={PauseTimmer || (data.includes("Admin"))}
            onChange={(event) => {
              setPauseTimmer(true);
              changeStatusApiCall(
                event?.target?.checked,
                item?.Email,
                setSwitchStatus
              );
              setTimeout(() => {
                setPauseTimmer(false);
              }, 10000);
            }}
          />
        </div>
      </td>

      <td className="user_btnn">
        <div
          style={{
            marginLeft: "35px",
            color: "var(--color-secondary-dark)",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          {loadingReset === index ? (
            <div className="d-flex align-items-center justify-content-center">
              <LockResetIcon style={{ color: "#dddddd" }} />
            </div>
          ) : (
            <LockOutlinedIcon
              onClick={() => {
                sendResetLink(item?.Email ?? "");
                setLoadingReset(index);
              }}
            />
          )}
        </div>
      </td>

      {/* ACTION */}
      {/* <td className="user_btnn">
        <div
          style={{
            marginLeft: "35px",
            color: "var(--color-secondary-dark)",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(
                  Routeconstant.USER_Orgination_mapping,
                  {
                    state: {
                      item,
                    },
                  }
                );
              }}
            >
              Org. mapping
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpen(true);
                setModuleCaller({ name: "Accessibility", mailId: item?.Email });
              }}
            >
              Accessibility
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpen(true);
                setModuleCaller({ name: "Role", mailId: item?.Email });
              }}
            >
              Role management
            </MenuItem>
          </Menu>

          <MoreVertIcon onClick={handleClick} />
        </div>
      </td> */}
    </tr>
  );
};

export default UserTableRow;
