export const genrateDownloadLink = (filename) => {
  const extension = filename.split(".").pop().toUpperCase();

  let url = "";
  switch (extension) {
    case "PDF":
      url = "data:application/pdf;base64,";
      break;
    case "XLSX":
      url =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      break;
    case "XLS":
      url = "data:application/vnd.ms-excel;base64,";
      break;
    case "DOCX":
      url =
        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, ";
      break;
    case "DOC":
      url = "data:application/msword;base64, ";
      break;
    case "MSG":
      url = "data:application/vnd.ms-outlook;base64, ";
      break;
    case "CSV":
      url = "data:text/csv;base64, ";
      break;
    case "HTML":
      url = "data:text/html;base64, ";
      break;
  }
  return url;
};
