const ENV = "PRD";
let basename = "";
let baseUrl = "";
let marginRms = false;
let baseOrderUrl = "";
let LOCAL_STORAGE_KEY = "";
let customerName = "BDG International";
if (ENV === "PRD") {
  basename = "";
  // homepage:- https://dashboard.bdginternational.com/
  // baseUrl = "https://dashboard.bdginternational.com/InsightsV2Api/";
  baseOrderUrl = "https://dev2.sflhub.com/SoftfreightLogicV2API/PurchaseOrder/";
  baseUrl = "https://live.sflhub.com/BDGInsightsV2API/";
  LOCAL_STORAGE_KEY = "BDGInsights_mvp2";
} else {
  basename = "BDGInsightsV2";
  baseOrderUrl = "https://dev2.sflhub.com/SoftfreightLogicV2API/PurchaseOrder/";
  baseUrl = "https://dev2.sflhub.com/BDGInsightsV2Api/";
  // baseUrl = "https://dev2.sflhub.com/SoftfreightLogicV2API/";
  LOCAL_STORAGE_KEY = "BDGInsights_mvp2";
  // homepage: "https://dev2.sflhub.com/BDGInsightsV2/"
}

export {
  basename,
  baseUrl,
  baseOrderUrl,
  LOCAL_STORAGE_KEY,
  customerName,
  marginRms,
};
// "start": "set PORT=4001 &&  react-scripts start", set port 4001 for order API's
