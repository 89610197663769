import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Modeoptions, Typeoptions } from "../../constant/sortshipmentoptions";
import { AuthContext } from "../../store/context/AuthProvider";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";

const FilterModalShipment = ({
  setSortmodal,
  sortmodal,
  setFilter,
  filter,
  status,
  getShipmentCollection,
  setStatus,
  setCurrentPage,
  countryList,
  setLoadingOnSearch,
  setCheckActive,
  filterObj,
}) => {
  const context = useContext(AuthContext);
  const handleClose = () => setSortmodal(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!context.gridViewShipment) {
      setStatus("");
    }
  }, []);

  return (
    <Dialog
      open={sortmodal}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogContent>
        <Card style={{ border: "none", boxShadow: "none" }}>
          <CardHeader
            action={
              <Button
                className="font-change"
                onClick={() => {
                  setFilter({
                    TransportMode: "",
                    ContainerType: "",
                    Origin: "",
                    Destination: "",
                    Carrier: "",
                    Shipper: "",
                    Consignor: "",
                    Consignee: "",
                    OrderNumber: "",
                    MasterBill: "",
                    FromETA: null,
                    ToETA: null,
                    ToETD: null,
                    FromETD: null,
                    FromATA: null,
                    ToATA: null,
                    FromATD: null,
                    ToATD: null,
                  });
                }}
              >
                Reset all
              </Button>
            }
            subheader={
              <Button
                className="font-change"
                color={"primary"}
                onClick={() => {
                  setSortmodal(false);
                  setLoadingOnSearch(true);
                  const clearObj = {
                    TransportMode: "",
                    ContainerType: "",
                    Origin: "",
                    Destination: "",
                    Carrier: "",
                    Shipper: "",
                    Consignor: "",
                    Consignee: "",
                    OrderNumber: "",
                    MasterBill: "",
                    FromETA: null,
                    ToETA: null,
                    ToETD: null,
                    FromETD: null,
                    FromATA: null,
                    ToATA: null,
                    FromATD: null,
                    ToATD: null,
                  };
                  filterObj.current = { ...clearObj };
                  setFilter(clearObj);
                  getShipmentCollection(0, status);
                }}
              >
                <KeyboardBackspaceIcon /> Back
              </Button>
            }
          />
          <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">
                      Transport Mode
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <Select
                        value={filter.TransportMode}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            TransportMode: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="">
                          <em className="font-change">None</em>
                        </MenuItem>
                        {Modeoptions.map((item, index) => {
                          return (
                            <MenuItem
                              className="font-change"
                              key={index}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">
                      Container Type
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <Select
                        value={filter.ContainerType}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            ContainerType: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="">
                          <em className="font-change">None</em>
                        </MenuItem>
                        {Typeoptions.map((item, index) => {
                          return (
                            <MenuItem
                              className="font-change"
                              key={index}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ----origin-- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">Origin</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <Select
                        value={filter.Origin}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            Origin: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="">
                          <em className="font-change">None</em>
                        </MenuItem>
                        {countryList.map((item, index) => {
                          return (
                            <MenuItem
                              className="font-change"
                              key={index}
                              value={item.Code}
                            >
                              {`${item.Description} (${item.Code})`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ----destination-- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">Destination</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <Select
                        value={filter.Destination}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            Destination: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="">
                          <em className="font-change">None</em>
                        </MenuItem>
                        {countryList.map((item, index) => {
                          return (
                            <MenuItem
                              className="font-change"
                              key={index}
                              value={item.Code}
                            >
                              {`${item.Description} (${item.Code})`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------carrier------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">Carrier</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.Carrier}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          setFilter((prev) => {
                            return { ...prev, Carrier: e.target.value };
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">Consignor</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.Consignor}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          setFilter((prev) => {
                            return { ...prev, Consignor: e.target.value };
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------Consignee------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">Consignee</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.Consignee}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          setFilter((prev) => {
                            return { ...prev, Consignee: e.target.value };
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------Order Number------- */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography>Order Number</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.OrderNumber}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          setFilter((prev) => {
                            return { ...prev, OrderNumber: e.target.value };
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid> */}
              {/* -------Master Bill------ */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography>Master Bill</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filter.MasterBill}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          setFilter((prev) => {
                            return { ...prev, MasterBill: e.target.value };
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid> */}
              {/* -------ETA------ */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={6} sm={6} xs={6}>
                    <Typography className="font-change">ETA</Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3.5}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ color: "black" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 "
                        label="Select Date"
                        value={filter?.FromETA}
                        maxDate={filter?.ToETA}
                        inputFormat="DD/MMM/YYYY"
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            FromETA: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={1} md={12} sm={12} xs={12}>
                    <Typography className="font-change" align="center">
                      to
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 "
                        inputFormat="DD/MMM/YYYY"
                        label="Select Date"
                        value={filter?.ToETA}
                        minDate={filter?.FromETA}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            ToETA: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={6} sm={6} xs={6}>
                    <Typography className="font-change">ETD</Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 "
                        inputFormat="DD/MMM/YYYY"
                        label="Select Date"
                        size="small"
                        value={filter?.FromETD}
                        maxDate={filter?.ToETD}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            FromETD: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={1} md={12} sm={12} xs={12}>
                    <Typography className="font-change" align="center">
                      to
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 "
                        inputFormat="DD/MMM/YYYY"
                        label="Select Date"
                        value={filter?.ToETD}
                        minDate={filter?.FromETD}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            ToETD: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={6} sm={6} xs={6}>
                    <Typography className="font-change">ATA</Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 "
                        inputFormat="DD/MMM/YYYY"
                        label="Select Date"
                        size="small"
                        value={filter?.FromATA}
                        maxDate={filter?.ToATA}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            FromATA: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={1} md={12} sm={12} xs={12}>
                    <Typography className="font-change" align="center">
                      to
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 "
                        inputFormat="DD/MMM/YYYY"
                        label="Select Date"
                        value={filter?.ToATA}
                        minDate={filter?.FromATA}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            ToATA: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={6} sm={6} xs={6}>
                    <Typography className="font-change">ATD</Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 "
                        inputFormat="DD/MMM/YYYY"
                        label="Select Date"
                        size="small"
                        value={filter?.FromATD}
                        maxDate={filter?.ToATD}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            FromATD: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={1} md={12} sm={12} xs={12}>
                    <Typography className="font-change" align="center">
                      to
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="w-100 "
                        inputFormat="DD/MMM/YYYY"
                        label="Select Date"
                        value={filter?.ToATD}
                        minDate={filter?.FromATD}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            ToATD: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className="d-flex justify-content-end">
            <Button
              className="font-change"
              onClick={() => {
                filterObj.current = { ...filter };
                navigate(Routeconstant.SHIPMENT_LISTING, { replace: true });
                setCheckActive(null);
                setLoadingOnSearch(true);
                getShipmentCollection(0, status); //api
                setCurrentPage(0); //page set
                setSortmodal(false); //off
              }}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default FilterModalShipment;
