import React from "react";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { basename } from "../../Config";
import { API_PATH } from "../../constant/apiPath";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import instance from "../../api/Interceptor";
import { useState } from "react";
import { Grid } from "@mui/material";
import { AuthContext } from "../../store/context/AuthProvider";
import { useContext } from "react";
import urlResolver from "../../utils/urlResolver";

function MapChart(props) {
  // console.log("props", props.filterOptions);
  const [analyticsMap, setanalyticsMap] = useState([]);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const context = useContext(AuthContext);
  useEffect(() => {
    if (context.filterLoaded) getOptionsForDocType();
  }, [props.sectorwiseDirection, props.filterOptions, context.filterLoaded]);

  const getOptionsForDocType = () => {
    const body = {
      ShpEtdFrom: "",
      ShpEtdTo: "",
      ShpEtaFrom: "",
      ShpEtaTo: "",
      ShpTransportMode: props.filterOptions?.filterMode
        ? props.filterOptions?.filterMode
        : "AIR",
      ShpOriginPortCode: "",
      ShpOriginPortName: "",
      ShpDestinationPortCode: "",
      ShpConsignorCode: "",
      ShpConsigneeCode: "",
      IncoTerm: "",
      ShpDirection: props.sectorwiseDirection ?? "IMP",
      Year: props.filterOptions?.filterYear
        ? parseInt(props.filterOptions?.filterYear)
        : 0,
      ShpCarrierCode: props?.filterOptions?.filterCarrier
        ? props.filterOptions?.filterCarrier
        : "",
    };
    instance
      .post(API_PATH.GET_ANALYTICS_SHIPMENT_MAP, body)
      .then((res) => {
        setanalyticsMap(res.data);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err);
      });
  };

  const onMarkerClick = (props, marker, e) => {
    if (window.innerWidth < 768) {
      setSelectedPlace(props);
      setShowingInfoWindow(true);
      setActiveMarker(marker);
    }
  };

  return (
    <Map
      google={props.google}
      zoom={1.1}
      style={{
        position: "absolute",
        width: "90%",
        height: "26.5rem",
        borderRadius: "12px",
      }}
    >
      {analyticsMap.length &&
        analyticsMap.map((item, index) => {
          return (
            <Marker
              onClick={onMarkerClick}
              key={index}
              icon={{
                url: urlResolver(`assets/Red_Point.gif`),
                scaledSize: new window.google.maps.Size(25, 25),
              }}
              title={`${item?.CountryName ?? "N/A"}(${item?.Count ?? "N/A"})`}
              name={`${item?.CountryName ?? "N/A"}(${item?.Count ?? "N/A"})`}
              position={{
                lat: item.Latitude,
                lng: item.Longitude,
              }}
            />
          );
        })}
      <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
        <div>
          <p>{selectedPlace?.title}</p>
        </div>
      </InfoWindow>
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0",
})(React.memo(MapChart));
