export const SortType = [
  "Mode",
  "Type",
  "Carrier",
  "House BL",
  "Shipper",
  "Consignee",
  "Consignor",
  "Order No#",
  "Master BL# ",
  "ETA",
  "ETD",
  "ATA",
  "ATD",
];
export const Modeoptions = ["SEA", "AIR", "ROA", "RAI", "COU"];
export const Typeoptions = [
  "LSE",
  "ULD",
  "CON",
  "BCN",
  "FCL",
  "LCL",
  "LQD",
  "BBK",
  "ROR",
  "FTL",
  "LTL",
  "OBC",
  "UNA",
  "BLK",
];
