import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import instance from "../../../../../api/Interceptor";
import { useState } from "react";
import { API_PATH } from "../../../../../constant/apiPath";
import { useEffect } from "react";
const CarrierCodesFilter = ({
  carrierList,
  filterOptions,
  setFilterOptions,
  setCarrierList,
  setCarrierListCopy,
  carrierListCopy,
}) => {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState(carrierList);
  const [temparr, setTemparr] = useState([]);
  // console.log("# -->", carrierListCopy);

  const filterCarrierList = (val) => {
    // console.log("#",val)
    let temp = carrierListCopy.filter((item) => {
      return item.FullName.toLowerCase().includes(val.toLowerCase());
    });
    setCarrierList(temp);
  };

  return (
    <div
      style={{
        padding: "2%",
      }}
    >
      <div
        style={{
          border: "0.1rem solid var(--color-primary)",
          borderRadius: "5px",
          padding: "2%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Carrier</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
      {open ? (
        <>
          <div
            style={{
              borderRadius: "7px",
              backgroundColor: "var(--color-miscellaneous-6)",
              marginTop: "2%",
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <input
              type="text"
              style={{
                border: "none",
                width: "80%",
                backgroundColor: "var(--color-miscellaneous-6)",
              }}
              onChange={(e) => {
                filterCarrierList(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              maxHeight: "25vh",
              overflowY: "auto",
            }}
          >
            <FormGroup
              style={{
                padding: "3%",
                overflowY: "auto",
              }}
            >
              {carrierList.map((code) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={false}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          // console.log("temparr", temparr);
                          let temp = [...temparr];
                          let selectedValue = e.target.value;
                          if (temp.includes(selectedValue)) {
                            temp = temp.filter((item) => item != selectedValue);
                          } else {
                            temp.push(e.target.value);
                          }

                          setTemparr(temp);
                          setFilterOptions({
                            ...filterOptions,
                            filterCarrier: temp.toString(),
                          });
                        }}
                        color="success"
                        name={`${code.FullName} (${code.Code})`}
                        value={code.Code}
                        // name="1"
                      />
                    }
                    label={`${code.FullName} (${code.Code})`}
                  />
                );
              })}
            </FormGroup>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default CarrierCodesFilter;
