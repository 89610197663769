import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import "./layout.css";

export const MainLayout = () => {
  return (
    <div>
      <div className="Header-Main">
        <Header />
      </div>
      <div className="outlet-main">
        <Outlet />
      </div>
    </div>
  );
};
