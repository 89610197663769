import React, { useContext, useEffect, useState } from "react";
import LayoutDetail from "../../../components/layout/LayoutDetail";
import "../shipment.css";
import { AuthContext } from "../../../store/context/AuthProvider";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { Divider, Grid, IconButton, Skeleton } from "@mui/material";
import { display } from "@mui/system";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { DateFormat } from "../../../utils/moment";

const ShipmentDetails = (props) => {
  const [shipmentDetails, setShipmentDetails] = useState({});
  const location = useLocation();
  const context = useContext(AuthContext);
  const [accordin, setAccordin] = useState({
    first: true,
    second: true,
    third: true,
    fourth: true,
    fifth: true,
  });

  const [loading, setLoading] = useState(false);
  const getShipmentDetails = () => {
    setLoading(true);
    const body = {
      ShipmentId: context?.shipmentId ?? "",
      TransportMode: "",
      ContainerType: "",
      Carrier: "",
      HouseBillNumber: "",
      Consignee: "",
      Consignor: "",
      OrderNumber: "",
      MasterBillNumber: "",
      ETAFrom: "",
      ETATo: "",
      ETDFrom: "",
      ETDTo: "",
      ATDFrom: "",
      ATDTo: "",
      ATAFrom: "",
      ATATo: "",
      EmailID: "",
      ShowDeveloperResponse: true,
      DateFrom: "",
      DateTo: "",
      Status: "",
      CurrentPage: 0,
      PageSize: 0,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_SHIPMENT_DETAILS, body)
      .then((res) => {
        setLoading(false);
        setShipmentDetails(res?.data[0] ?? {});
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.hasOwnProperty("shipmentDetails")) {
      setShipmentDetails(props.shipmentDetails);
    } else {
      getShipmentDetails();
    }
  }, [context?.shipmentId]);
  const navigate = useNavigate();
  useEffect(() => {
    if (context?.shipmentId?.length === 0) {
      navigate(Routeconstant.SHIPMENT_LISTING);
    }
  }, []);
  const CompleteAddress = ({ address1, address2, city, postCode }) => {
    let tempAddress = [];
    if (address1?.length) {
      tempAddress.push(address1);
    }
    if (address2?.length) {
      tempAddress.push(address2);
    }
    if (city?.length) {
      tempAddress.push(city);
    }
    if (postCode?.length) {
      tempAddress.push(postCode);
    }
    if (tempAddress.length) {
      return (
        <Grid item lg={2.5} md={4} sm={4} xs={6}>
          <Grid container spaing={0}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p className="m-0 text-head">Address</p>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p className="m-0 text-sub" title={tempAddress.toString()}>
                {tempAddress.toString()}
              </p>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };
  console.log(
    "shipmentDetails?.PickupCompanyName",
    shipmentDetails?.PickupCompanyName
  );
  return (
    <LayoutDetail shipmentDetails={shipmentDetails}>
      {props.loading ? (
        <div className="skeleton-div">
          <Skeleton style={{ height: "45rem", marginTop: "1rem" }} />
        </div>
      ) : (
        <>
          {context.gridViewShipment ? (
            <div className="p-2">
              <p className="basic-head">Basic Details</p>
            </div>
          ) : null}
          {/* --Shipment specifics-- */}
          <Grid
            container
            spacing={1}
            sx={{
              padding: "0% 1%",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  minWidth: "fit-content",
                }}
                className="m-0 basic-specific"
              >
                Shipment Specifics
              </p>
              <div
                style={{
                  border: "1px solid var(--color-secondary-light) ",
                  width: "90%",
                  opacity: "0.4",
                  marginLeft: "20px",
                }}
              />

              <IconButton
                onClick={(e) => {
                  if (accordin.first === true) {
                    setAccordin({ ...accordin, first: false });
                  } else {
                    setAccordin({ ...accordin, first: true });
                  }
                }}
              >
                <ExpandCircleDownOutlinedIcon color="secondary" />
              </IconButton>
            </Grid>
            {/* <Grid item xs={3} style={{ paddingLeft: "1.5rem" }}>
              <p className="m-0 basic-specific">Shipment Specifics</p>
            </Grid>

            <Grid item xs={9} className="d-flex align-items-center dash-rms">
              <Divider
                variant="middle"
                flexItem
                sx={{
                  backgroundColor: " var(--color-secondary-light)",
                  width: "88%",
                  marginTop: "0.8rem",
                }}
              />
              <IconButton
                onClick={(e) => {
                  if (accordin.first === true) {
                    setAccordin({ ...accordin, first: false });
                  } else {
                    setAccordin({ ...accordin, first: true });
                  }
                }}
              >
                <ExpandCircleDownOutlinedIcon color="primary" />
              </IconButton>
            </Grid> */}
            {accordin.first ? (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "1%", paddingBottom: "1rem" }}
                >
                  <Grid item lg={2.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0  text-head">Master Bill No.</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {shipmentDetails &&
                          shipmentDetails?.MasterBillNumber && (
                            <p
                              className="m-0 text-sub"
                              title={shipmentDetails?.MasterBillNumber}
                            >
                              {shipmentDetails?.MasterBillNumber[0] ?? null}{" "}
                              <span>
                                {shipmentDetails?.MasterBillNumber.length > 1
                                  ? "..."
                                  : ""}
                              </span>
                            </p>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head ">House Bill No.</p>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-sub">
                          {shipmentDetails?.HouseBillNumber ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head ">Order Reference No.</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-sub">
                          {shipmentDetails?.OrderRef ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head">Type of Shipment</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-sub">
                          {shipmentDetails?.ShipmentContainerMode ??
                            shipmentDetails?.ContainerMode ??
                            null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={1.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <p className="m-0 text-head ">INCO Terms</p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className="m-0 text-sub">
                        {shipmentDetails?.INCOTerm ?? null}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <p className="m-0 text-head ">Shipper Reference</p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className="m-0 text-sub">
                        {shipmentDetails?.ShipperRef ?? null}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          {/* --Container and cargo description-- */}
          <Grid
            container
            spacing={1}
            sx={{
              padding: "0% 1%",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  minWidth: "fit-content",
                }}
                className="m-0 basic-specific"
              >
                Container and cargo description
              </p>

              <div
                style={{
                  border: "1px solid var(--color-secondary-light) ",
                  width: "90%",
                  opacity: "0.4",
                  marginLeft: "20px",
                }}
              />

              <IconButton>
                <ExpandCircleDownOutlinedIcon
                  color="secondary"
                  onClick={() => {
                    if (accordin.second === true) {
                      setAccordin({ ...accordin, second: false });
                    } else {
                      setAccordin({ ...accordin, second: true });
                    }
                  }}
                />
              </IconButton>
            </Grid>

            {accordin.second ? (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "1%", paddingBottom: "1rem" }}
                >
                  <Grid item lg={2.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head "> Carrier</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {shipmentDetails && shipmentDetails?.CarrierName && (
                          <p
                            className="m-0 text-sub"
                            title={shipmentDetails?.CarrierName}
                          >
                            {shipmentDetails?.CarrierName[0] ?? null}{" "}
                            <span>
                              {shipmentDetails?.CarrierName.length > 1
                                ? "..."
                                : ""}
                            </span>
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head">Container No.</p>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {shipmentDetails &&
                          shipmentDetails.ContainerNumbers && (
                            <p
                              className="m-0 text-sub"
                              title={shipmentDetails.ContainerNumbers}
                            >
                              {shipmentDetails.ContainerNumbers[0] ?? null}{" "}
                              <span>
                                {shipmentDetails.ContainerNumbers.length > 1
                                  ? "..."
                                  : ""}
                              </span>
                            </p>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head ">Container Count</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p
                          className="m-0 text-sub"
                          title={shipmentDetails?.FCLContainerCount ?? null}
                        >
                          {shipmentDetails?.FCLContainerCount ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={1.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <p className="m-0 text-head">Type</p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className="m-0 text-sub"
                        title={shipmentDetails?.ReleaseType ?? null}
                      >
                        {shipmentDetails?.ReleaseType ?? null}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item lg={1.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head ">Weight</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p
                          className="m-0 text-sub"
                          title={shipmentDetails?.TotalWeight ?? null}
                        >
                          {shipmentDetails?.TotalWeight ?? null}
                          {shipmentDetails?.TotalWeightUQ ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <p className="m-0 text-head">Volume</p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className="m-0 text-sub"
                        title={shipmentDetails?.TotalVolume ?? null}
                      >
                        {shipmentDetails?.TotalVolume ?? null}
                        {shipmentDetails?.TotalVolumeUQ ?? null}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          {/* --Additional details-- */}
          <Grid
            container
            spacing={1}
            sx={{
              padding: "0% 1%",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  minWidth: "fit-content",
                }}
                className="m-0 basic-specific"
              >
                Additional Details
              </p>
              <div
                style={{
                  border: "1px solid var(--color-secondary-light) ",
                  width: "90%",
                  opacity: "0.4",
                  marginLeft: "20px",
                }}
              />
              <IconButton
                onClick={() => {
                  if (accordin.third === true) {
                    setAccordin({ ...accordin, third: false });
                  } else {
                    setAccordin({ ...accordin, third: true });
                  }
                }}
              >
                <ExpandCircleDownOutlinedIcon color="secondary" />
              </IconButton>
            </Grid>

            {accordin.third ? (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "1%", paddingBottom: "1rem" }}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head">Goods Description</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-sub">
                          {shipmentDetails?.GoodsDescription ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head text-head">
                          Marks and Comments
                        </p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head text-sub">
                          {shipmentDetails?.MarksandNumberDescription ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head text-head">
                          Client Visible Note
                        </p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head text-sub">
                          {shipmentDetails?.ClientVisibleNoteDescription ??
                            null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          {/* Address */}
          {shipmentDetails?.EstimatedPickupDate && (
            <Grid
              container
              spacing={1}
              sx={{
                padding: "0% 1%",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    minWidth: "fit-content",
                  }}
                  className="m-0 basic-specific"
                >
                  Pickup details
                </p>
                <div
                  style={{
                    border: "1px solid var(--color-secondary-light) ",
                    width: "90%",
                    opacity: "0.4",
                    marginLeft: "20px",
                  }}
                />
                <IconButton
                  onClick={() => {
                    if (accordin.fourth === true) {
                      setAccordin({ ...accordin, fourth: false });
                    } else {
                      setAccordin({ ...accordin, fourth: true });
                    }
                  }}
                >
                  <ExpandCircleDownOutlinedIcon color="secondary" />
                </IconButton>
              </Grid>
              {accordin.fourth ? (
                <>
                  {/* Pickup */}

                  <Grid
                    container
                    spacing={2}
                    style={{ paddingLeft: "1%", paddingBottom: "1rem" }}
                  >
                    {shipmentDetails?.PickupCompanyName &&
                      shipmentDetails?.PickupCompanyName.length && (
                        <Grid item lg={2.5} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head "> Company Name</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub"
                                title={shipmentDetails?.PickupCompanyName}
                              >
                                {shipmentDetails?.PickupCompanyName}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    <CompleteAddress
                      address1={shipmentDetails?.PickupAddress1}
                      address2={shipmentDetails?.PickupAddress2}
                      city={shipmentDetails?.PickupCity}
                      postCode={shipmentDetails?.PickupPostCode}
                    />

                    {shipmentDetails?.PickupEmail &&
                      shipmentDetails?.PickupEmail.length && (
                        <Grid item lg={1.5} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <p className="m-0 text-head ellipsis-p">Email</p>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p
                              className="m-0 text-sub ellipsis-p"
                              title={shipmentDetails?.PickupEmail ?? null}
                            >
                              {shipmentDetails?.PickupEmail ?? null}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    {shipmentDetails?.PickupPhone &&
                      shipmentDetails?.PickupPhone.length && (
                        <Grid item lg={3} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head ">Phone</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub ellipsis-p"
                                title={shipmentDetails?.PickupPhone ?? null}
                              >
                                {shipmentDetails?.PickupPhone ?? null}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {shipmentDetails?.EstimatedPickupDate &&
                      shipmentDetails?.EstimatedPickupDate.length && (
                        <Grid item lg={3} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head ">Estimated Pickup</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub ellipsis-p"
                                title={
                                  shipmentDetails?.EstimatedPickupDate ?? null
                                }
                              >
                                {DateFormat(
                                  shipmentDetails?.EstimatedPickupDate
                                ) ?? null}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {shipmentDetails?.ActualPickupDate &&
                      shipmentDetails?.ActualPickupDate.length && (
                        <Grid item lg={3} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head ">Actual Pickup</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub ellipsis-p"
                                title={
                                  shipmentDetails?.ActualPickupDate ?? null
                                }
                              >
                                {DateFormat(
                                  shipmentDetails?.ActualPickupDate
                                ) ?? null}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </>
              ) : null}
            </Grid>
          )}
          {shipmentDetails?.EstimatedDeliveryDate && (
            <>
              <Grid
                container
                spacing={1}
                sx={{
                  padding: "0% 1%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      minWidth: "fit-content",
                    }}
                    className="m-0 basic-specific"
                  >
                    Delivery Details
                  </p>
                  <div
                    style={{
                      border: "1px solid var(--color-secondary-light) ",
                      width: "90%",
                      opacity: "0.4",
                      marginLeft: "20px",
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      if (accordin.fifth === true) {
                        setAccordin({ ...accordin, fifth: false });
                      } else {
                        setAccordin({ ...accordin, fifth: true });
                      }
                    }}
                  >
                    <ExpandCircleDownOutlinedIcon color="secondary" />
                  </IconButton>
                </Grid>
              </Grid>

              {accordin.fifth ? (
                <>
                  {/* Destination */}

                  <Grid
                    container
                    spacing={2}
                    style={{ paddingLeft: "1%", paddingBottom: "1rem" }}
                  >
                    {shipmentDetails?.DeliveryCompanyName &&
                      shipmentDetails?.DeliveryCompanyName.length && (
                        <Grid item lg={2.5} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head "> Company Name</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub ellipsis-p"
                                title={shipmentDetails?.DeliveryCompanyName}
                              >
                                {shipmentDetails?.DeliveryCompanyName}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    <CompleteAddress
                      address1={shipmentDetails?.DeliveryAddress1}
                      address2={shipmentDetails?.DeliveryAddress2}
                      city={shipmentDetails?.DeliveryCity}
                      postCode={shipmentDetails?.DeliveryPostCode}
                    />

                    {shipmentDetails?.DeliveryEmail &&
                      shipmentDetails?.DeliveryEmail.length && (
                        <Grid item lg={3} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <p className="m-0 text-head">Email</p>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p
                              className="m-0 text-sub ellipsis-p"
                              title={shipmentDetails?.DeliveryEmail ?? null}
                            >
                              {shipmentDetails?.DeliveryEmail ?? null}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    {shipmentDetails?.DeliveryPhone &&
                      shipmentDetails?.DeliveryPhone.length && (
                        <Grid item lg={1.5} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head ellipsis-p ">Phone</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub ellipsis-p "
                                title={shipmentDetails?.DeliveryPhone ?? null}
                              >
                                {shipmentDetails?.DeliveryPhone ?? null}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {shipmentDetails?.EstimatedDeliveryDate &&
                      shipmentDetails?.EstimatedDeliveryDate.length && (
                        <Grid item lg={1.5} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head ellipsis-p ">
                                Estimated delivery
                              </p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub"
                                title={
                                  shipmentDetails?.EstimatedDeliveryDate ?? null
                                }
                              >
                                {DateFormat(
                                  shipmentDetails?.EstimatedDeliveryDate
                                ) ?? null}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {shipmentDetails?.ActualDeliveryDate &&
                      shipmentDetails?.ActualDeliveryDate.length && (
                        <Grid item lg={1.5} md={4} sm={4} xs={6}>
                          <Grid container spaing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p className="m-0 text-head ellipsis-p ">
                                Actual delivery
                              </p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <p
                                className="m-0 text-sub"
                                title={
                                  shipmentDetails?.ActualDeliveryDate ?? null
                                }
                              >
                                {DateFormat(
                                  shipmentDetails?.ActualDeliveryDate
                                ) ?? null}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </LayoutDetail>
  );
};

export default ShipmentDetails;
