import * as React from "react";
import TransferListModal from "./TransferListModal";
import { useState } from "react";
import { useEffect } from "react";
import { getModuleData } from "../userApi/userApiDefination";
import { Skeleton } from "@mui/material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function Modal({ setOpen, open, moduleCaller }) {
  const [loading, setLoading] = useState(false);
  const [dataModel, setDataModel] = useState({});
  const [dataRole, setDataRole] = useState({
    USER: true,
    Admin: false,
    Buyer: true,
    Suppler: true,
    "approver ": false,
  });
  // method to close the modal
  const handleClose = () => {
    setOpen(false);
  };

  const Loader = () => {
    return (
      <div  className="d-flex gap-4">
        <Skeleton variant="rounded" width={"25em"} height={"17em"} />
        <Skeleton variant="rounded" width={"25em"} height={"17em"} />
      </div>
    );
  };
  useEffect(() => {
    setLoading(true);
    if (moduleCaller?.name === "Accessibility")
      getModuleData(moduleCaller?.mailId, setDataModel, setLoading);
  }, []);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogContent>
        <Card style={{ border: "none", boxShadow: "none" }}>
          <CardHeader
            action={<Button>Reset all</Button>}
            subheader={
              <Button color={"primary"}>
                <KeyboardBackspaceIcon /> Back
              </Button>
            }
          />
          <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />
          <CardContent>
            {loading ? (
              <Loader />
            ) : (
              <TransferListModal
                senderName={moduleCaller?.name}
                data={
                  moduleCaller?.name === "Accessibility" ? dataModel : dataRole
                }
                title={
                  moduleCaller?.name === "Accessibility"
                    ? ["Modules", "Acess"]
                    : ["Roles", "Roles assigned"]
                }
                subtitle={
                  moduleCaller?.name === "Accessibility"
                    ? ["module available", "Access granted"]
                    : ["Available", "Assinged"]
                }
              />
            )}
          </CardContent>
          <CardActions className="d-flex justify-content-end">
            <Button
              onClick={() => {
                // api call will be mention here
              }}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
