import { createSlice } from "@reduxjs/toolkit";

const reportTableSlice = createSlice({
  name: "reportTableSlice",
  initialState: {
    tableHeaders: [],
    tableHeadersCopy: [],
    tableData: [],
    tableModel: {
      tableColumn: false,
      tableFilter: false,
    },
  },
  reducers: {
    setTableHeaders: (state, action) => {
      state.tableHeaders = action.payload;
      state.tableHeadersCopy = action.payload;
    },
    resetTableHeadersCopy: (state, action) => {
      state.tableHeaders = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setTableModel: (state, action) => {
      state.tableModel[action.payload.name] = action.payload.value;
    },
    updateTableHeaders: (state, action) => {
      state.tableHeaders[action.payload.index].value = action.payload.value;
    },
  },
});
export const {
  setTableHeaders,
  updateTableHeaders,
  setTableData,
  setTableModel,
  resetTableHeadersCopy,
} = reportTableSlice.actions;
export default reportTableSlice.reducer;
