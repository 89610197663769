
   const fileTypeDescriptions = {
    "ACC": "ACCESSORIAL CHARGES",
    "ACR": "Accessorial Charge Request",
    "ADD": "ADD/CVD-Anti Dumping Countervailing",
    "AES": "AES information",
    "ARC": "Arrival Notice and Charge Sheet",
    "ARN": "Arrival Notice",
    "INV": "Invoice",
    "BCA": "Booking Cartage Advice",
    "BCT": "Beneficiary Certificate",
    "BDR": "Bank Draft",
    "BKC": "Booking Confirmation",
    "CAD": "Cartage Advice",
    "CAR": "Category- Cartage Advice With Receipt",
    "CBP": "Customs Doc Pack",
    "CEQ": "Customs Entry Question",
    "CER": "Customs Error",
    "CIV": "Commercial Invoice",
    "COL": "Collection Letter",
    "COO": "Certificate of Origin",
    "CQB": "Quote Client Attachment",
    "CSS": "Container Summary Sheet",
    "CST": "Customs Status Advice",
    "CTR": "Container Release",
    "DAN": "Documents Available Notice",
    "DBL": "Draft Bill of Lading",
    "DGF": "Dangerous Goods Form",
    "DLB": "Delivery Labels",
    "DOR": "Delivery Order",
    "DRW": "Drawing / Schematic",
    "ECA": "Export Cartage Advice",
    "ECM": "Export Coload Master Manifest",
    "ECR": "Export Cartage Advice with Receipt",
    "EPL": "Entry Proof of Release",
    "EPR": "Entry Print/ Customs Declaration Documents",
    "EXA": "Export Authority",
    "EXD": "Exporter Documents",
    "EXR": "Export Clearance Request",
    "EXV": "VAT/GST Exporter Exemption (AR and AP Invoicing)",
    "FCR": "Forwarders Cargo Receipt",
    "FDA": "FDA documents",
    "FPP": "FPPI",
    "FTZ": "FTZ E-214 PACK",
    "FUM": "Fumigation Certificate",
    "HBL": "House Waybill/Bill of Lading",
    "ICA": "Import Cartage Advice",
    "ICM": "Import Coload Master Manifest",
    "ICR": "Import Cartage Advice with Receipt",
    "IDC": "Identification Check",
    "ILR": "Invoice Lines Report",
    "INS": "Customer Marine Ins info",
    "ISF": "ISF Form",
    "ISL": "ISL Late",
    "LAC": "LACEY ACT",
    "LDL": "Load List",
    "LID": "Letter of Indemnity",
    "MCD": "Miscellaneous Customs Document",
    "MCF": "Miscellaneous CFS Document",
    "MDC": "Manufacturer's Declaration",
    "MFD": "Miscellaneous Freight Document",
    "MOD": "Miscellaneous Order Management Document",
    "MSH": "Master House",
    "NEW": "CSR Category- New Client Document Pack",
    "BPW": "Category- new17",
    "OBL": "Original Bill Of Lading",
    "OUT": "Outturn Report",
    "PER": "Permit",
    "PGA": "Partner Government Agency Recap",
    "PKD": "Packing Declaration",
    "PKL": "Packing List",
    "POA": "Power of Attorney",
    "POD": "Proof Of Delivery",
    "PSC": "POST SUMMARY CORRECTION",
    "PUR": "SCL Category- Purchase Order",
    "CSR": "Category- Booking/Pick up information",
    "QPP": "Quarantine Print Preview",
    "QUF": "Quote Follow up",
    "QUO": "SCL Category- QUOTE BACK UP",
    "RPO": "Revoked POA",
    "RRA": "Release/Removal Authority",
    "RSB": "PTRS Reportable Small Business",
    "SAD": "Shipping Advice",
    "SDN": "Shippers Departure Notice",
    "SEC": "HRE Category- Staff / Group Security Documents",
    "SHP": "Shipping Documents",
    "SOA": "Statement of Account",
    "TSO": "TSCA Form"
  }

  export default fileTypeDescriptions;