import { createSlice } from "@reduxjs/toolkit";
export const headerSlice = createSlice({
  name: "headerSlice",
  initialState: {
    currentStepper: 0,
    maxStepper: 4,
  },
  reducers: {
    increase: (state, action) => {
      if (state.currentStepper <= state.maxStepper)
        state.currentStepper = state.currentStepper + 1;
    },
    decrease: (state, action) => {
      if (state.currentStepper) {
        state.currentStepper = state.currentStepper - 1;
      }
    },
    resetStepper: (state, action) => {
      state.currentStepper = 0;
    },
  },
});
export const { increase, decrease, resetStepper } = headerSlice.actions;
export default headerSlice.reducer;
