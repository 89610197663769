import { styled, Typography } from "@mui/material";
import React from "react";
const DetailsTypo = styled(Typography)(({ theme }) => ({
  fontSize: "0.9rem ",
  color: "#5B5675",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  //   padding: "1.8%",
}));
export default function detailstypo(props) {
  return (
    <DetailsTypo
    // align={props?.align ? props.align : ""}
    // variant={props?.variant ? props.variant : ""}
    // color="#5B5675"
    >
      {props.children}
    </DetailsTypo>
  );
}
