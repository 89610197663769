import { createSlice } from "@reduxjs/toolkit";
const orderplanSlice = createSlice({
  name: "orderplan",
  initialState: {
    selectedTab: 0,
    tabData: [
      {
        index: 0,
        originCountryCode: "",
        originPort: "",
        destinationCountryCode: "",
        desnationPort: "",
        weeks: 1,
        departureDate: "",
        cutOffDate: "",
        arrivalDate: "",
        direction: "",
        vessel: "",
        voyage: "",
        carrier: "",
      },
    ],
  },
  reducers: {
    addTab: (state, action) => {
      //   state.tab.index = action.payload.index;

      let tempObj = { index: action.payload.index, countryCode: "" };
      state.tabData.push(tempObj);
    },
    removeTab: (state, action) => {
      state.tabData.splice(action.payload.index, 1);
    },
    selectTab: (state, action) => {
      state.selectedTab = action.payload.index;
    },
    updateOrderPlan: (state, action) => {
      state.tabData[action.payload.index][action.payload.name] =
        action.payload.value;
    },
    updatetabData: (state, action) => {
      state.tabData[0] = action.payload.value;
    },
  },
});
export const { addTab, removeTab, selectTab, updateOrderPlan, updatetabData } =
  orderplanSlice.actions;
export default orderplanSlice.reducer;
