import { createSlice } from "@reduxjs/toolkit";
export const linedetailsSlice = createSlice({
  name: "lineDetails",
  initialState: [
    // {
    //   lineNo: 0,
    //   itemNo: 0,
    //   description: "",
    //   innerPacks: "",
    //   innerPackageType: "",
    //   outerPack: "",
    //   outerPackageType: "",
    //   outerPackLength: "",
    //   outerPackWidth: "",
    //   outerPackHeight: "",
    //   unitOfDimenssions: "",
    //   qtyOrdered: "",
    //   qtyReceived: "",
    //   qtyRemaining: "",
    //   unitOfQty: "",
    //   actualVolume: "",
    //   unitOfVolume: "",
    //   actualWeight: "",
    //   unitOfWeight: "",
    //   itemPrice: "",
    //   totalPrice: "",
    //   reqInStoreDate: "",
    //   incoTerm: "",
    //   additionalTerms: "",
    //   confirmNumber: "",
    //   containerNumber: "",
    //   confirmDate: "",
    //   deleteIndex: 0,
    // },
  ],
  reducers: {
    addLine: (state, action) => {
      let tempObj = {
        lineNo: action.payload,
        itemNo: "",
        description: "",
        innerPacks: "",
        innerPackageType: "",
        outerPack: "",
        outerPackageType: "",
        outerPackLength: "",
        outerPackWidth: "",
        outerPackHeight: "",
        unitOfDimenssions: "",
        qtyOrdered: "",
        qtyReceived: "",
        qtyRemaining: "",
        unitOfQty: "",
        actualVolume: "",
        unitOfVolume: "",
        actualWeight: "",
        unitOfWeight: "",
        itemPrice: "",
        totalPrice: "",
        reqInStoreDate: "",
        incoTerm: "",
        additionalTerms: "",
        confirmNumber: "",
        containerNumber: "",
        confirmDate: "",
        deleteIndex: 0,
      };
      state = state.push(tempObj);
    },
    updateLineDetails: (state, action) => {
      // console.log("Marvelv/sDC", action.payload);
      state[action.payload.index][action.payload.name] = action.payload.value;
    },
    deleteLine: (state, action) => {
      state.splice(action.payload, 1);
      console.info("stateinaction", action);
    },
    updateObj: (state, action) => {
      state[0] = action.payload.value;
    },
  },
});
export const { addLine, updateLineDetails, deleteLine, updateObj } =
  linedetailsSlice.actions;
export default linedetailsSlice.reducer;
