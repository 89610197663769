import { createSlice } from "@reduxjs/toolkit";
const orderspecificErrors = createSlice({
  name: "orderspecificError",
  initialState: {},
  reducers: {
    addErrors: (state, action) => {
      state.errors = action.payload.errorsObj;
    },
  },
});
export const { addErrors } = orderspecificErrors.actions;
export default orderspecificErrors.reducer;
