import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { useEffect } from "react";
import { useState } from "react";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthProvider";

export default function Index({ notificationOpen, setNotificationOpen }) {
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const getNotificationData = () => {
    setLoading(true);
    instance
      .get(API_PATH.NOTIFICATION_API)
      .then((res) => {
        setNotificationData(res?.data ?? []);
      })
      .catch((err) => {
        setNotificationData([]);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  return (
    <Drawer
      anchor={"right"}
      width={800}
      open={notificationOpen}
      onClose={() => {
        setNotificationOpen(false);
      }}
    >
      <Box
        sx={{
          width: { xs: 250, sm: 300, md: 500 },
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Notifications
          <IconButton onClick={() => setNotificationOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Container>

        <Divider
          sx={{
            width: "100%",
          }}
        />
        {loading ? (
          <div className="skeleton-div">
            <Skeleton style={{ height: "4rem", margin: "0.7rem" }} />
          </div>
        ) : (
          <>
            {notificationData.length ? (
              <>
                {notificationData.map((item, index) => {
                  return (
                    <div style={{ padding: "0 1rem" }} key={index}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          padding: "0.6rem 0",
                        }}
                      >
                        <Typography
                          onClick={() => {
                            navigate(Routeconstant.SHIPMENT_LISTING);
                            setNotificationOpen(false);
                            context.setGridViewShipment(false);
                          }}
                          style={{
                            marginRight: "2px",
                            color: "var( --color-primary-dark)",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item?.EV_ShipmentID ?? "N/A"}
                        </Typography>
                        <Typography
                          sx={{
                            marginRight: "2px",
                            display: "flex",
                            alignItems: "center",
                            margin: "0 1rem",
                          }}
                        >
                          -
                        </Typography>
                        <Typography>
                          {item?.EV_EventDescription ?? "N/A"}
                        </Typography>
                      </Box>
                      <Divider
                        sx={{
                          width: "100%",
                        }}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="d-flex justify-content-center font-change">
                No data found!
              </div>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
}
