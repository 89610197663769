import { createSlice } from "@reduxjs/toolkit";
const createOrderSlice = createSlice({
  name: "inputData",
  initialState: {
    loading: false,
    response: {},
  },
  reducers: {
    updateLoading: (state, action) => {
      state.loading = action.payload.load;
    },
    updateResponse: (state, action) => {
      state.response = action.payload.resp;
    },
  },
});
export const { updateLoading, updateResponse } = createOrderSlice.actions;
export default createOrderSlice.reducer;
