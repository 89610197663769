import { configureStore } from "@reduxjs/toolkit";
import orderSlice from "./orderRedux/createOrder/orderspecificslice";
import headerSlice from "./orderRedux/createOrder/headerSlice";
import orderspecificerrorslice from "./orderRedux/createOrder/orderErrors/orderspecificerrorslice";
import linedetailsSlice from "./orderRedux/createOrder/linedetailsSlice";
import orderplanSlice from "./orderRedux/createOrder/orderplanSlice";
import inputSlice from "./orderRedux/createOrder/inputSlice";
import docSlice from "./orderRedux/createOrder/docSlice";
import createOrderSlice from "./orderRedux/createOrderSlice";
import orderPermission from "./orderRedux/orderPermission";
import reportTableSlice from "./orderRedux/reports/reportTableSlice";
export default configureStore({
  reducer: {
    order: orderSlice,
    header: headerSlice,
    orderSpecificError: orderspecificerrorslice,
    linedetails: linedetailsSlice,
    orderplan: orderplanSlice,
    input: inputSlice,
    documents: docSlice,
    createOrderData: createOrderSlice,
    orderPermission: orderPermission,
    reportTable: reportTableSlice,
  },
});
