import React, { useRef, useState } from "react";
import BtnOutline from "../../components/button/BtnOutline";
import UserTable from "./component/UserTable";
import "./User.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { API_PATH } from "../../constant/apiPath";
import instance from "../../api/Interceptor";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import moment from "moment/moment";
import urlResolver from "../../utils/urlResolver";

const Index = () => {
  const lableToSort = useRef(null);
  const [userFilter, setUserFilter] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("Asc");
  const filterObj = useRef({});
  const [searchValidation, setSearchValidation] = useState(true);

  const [pageNo, setPageNo] = useState(0);
  const [filterData, setFilterData] = useState({
    UserName: "",
    OrgFullName: "",
    Orgcode: "",
    RegisterdDateFrom: null,
    RegisterdDateTo: null,
    LastLoginDateFrom: null,
    LastLoginDateTo: null,
    AccessGranted: "",
    Role: "",
    ActiveStatus: "",
  });
  const [checkFirst, setCheckFirst] = useState(true);
  const [backDropLoader, setBackDropLoader] = useState(false);
  const [loading, setLoading] = useState({
    initial: false,
    loadMore: false,
  });
  const ActiveStatusCode = (status) => {
    const codeobj = {
      Active: 1,
      "": -1,
      InActive: 0,
    };
    return codeobj[status];
  };
  const inputValidation = (input) => {
    const regex = new RegExp("^[^<>()*]*$");
    return regex.test(input);
  };

  const getAllUser = (currentPage, filters, SearchData) => {
    if (currentPage === 0 && checkFirst) {
      setLoading({ ...loading, initial: true });
    } else {
      setLoading({ ...loading, loadMore: true });
    }
    if (currentPage == 0) setPageNo(0);
    const body = {
      OrgCode: filterObj.current?.Orgcode ?? "",
      OrgFullName: filterObj.current?.OrgFullName ?? "",
      EmailId: "",
      SearchData: SearchData?.trim() ?? "",
      Role: filterObj.current?.Role ?? "",
      RegisteredDateFrom: filterObj.current?.RegisterdDateFrom
        ? moment(new Date(filterObj.current?.RegisterdDateFrom)).format(
            "yyyy-MM-DD"
          )
        : "",
      RegisteredDateTo: filterObj.current?.RegisterdDateTo
        ? moment(new Date(filterObj.current?.RegisterdDateTo)).format(
            "yyyy-MM-DD"
          )
        : "",
      LastLoginUtcDateFrom: filterObj.current?.LastLoginDateFrom
        ? moment(new Date(filterObj.current?.LastLoginDateFrom)).format(
            "yyyy-MM-DD"
          )
        : "",
      LastLoginUtcDateTo: filterObj.current?.LastLoginDateTo
        ? moment(new Date(filterObj.current?.LastLoginDateTo)).format(
            "yyyy-MM-DD"
          )
        : "",
      ContactName: filterObj.current?.UserName?.trim() ?? "",
      LoginActive: ActiveStatusCode(filterObj.current?.ActiveStatus),
      ContactNumber: "",
      Access: filterObj.current?.AccessGranted ?? "",
      SortColumnName: lableToSort.current?.ColName ?? "",
      SortDirection: lableToSort.current?.ColDir ?? "",
      CurrentPage: currentPage,
      PageSize: 10,
    };

    instance
      .post(API_PATH.GET_ALL_USER, body)
      .then((res) => {
        setUserCount(res?.totalCount ?? 0);
        setLoading({ ...loading, initial: false, loadMore: false });
        if (currentPage === 0) {
          setUserData(res.data);
        } else {
          setUserData([...userData, ...res.data]);
        }
      })
      .catch((err) => {
        setLoading({ ...loading, initial: false, loadMore: false });
        console.log(err);
        setUserData([]);
      });
  };
  useEffect(() => {
    getAllUser(pageNo, filterData, "");
    setCheckFirst(false);
  }, []);

  const searchIcon = (e) => {
    getAllUser(0, filterData, search);
    setPageNo(0);
  };
  function isObject(value) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  }
  return (
    <>
      {loading.initial ? (
        <div className="skeleton-div">
          <Skeleton style={{ height: "40rem", marginTop: "1rem" }} />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="d-flex">
              <div
                className=" headersearch searchShipmentDiv "
                style={{ width: "95%", marginRight: "10px" }}
              >
                <input
                  type="text"
                  className="form-control "
                  placeholder=" Search by user name/ Email ID"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    const validation = inputValidation(search);
                    setSearchValidation(validation);
                    if (e.key === "Enter" && validation) searchIcon(e);
                  }}
                />
                <img
                  src={urlResolver("assets/search_icon.svg")}
                  onClick={(e) => {
                    if (search && inputValidation(search)) {
                      searchIcon(e);
                    }
                  }}
                  style={{ color: "rgba(var(--text-heading-dark))" }}
                  className="searchIcon"
                />
                {search && (
                  <CloseOutlinedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      cursor: "pointer",
                    }}
                    className="searchClearIcon"
                    onClick={() => {
                      setSearchValidation(true);

                      setSearch("");
                      getAllUser(0, filterData, "");
                    }}
                  />
                )}
              </div>
              <div
                className={` d-flex justify-content-center  mt-2 mb-2 align-items-center filter-div ${
                  Object.values(filterObj.current).filter((element) => {
                    if (element?.length > 0 || isObject(element)) return 1;
                  }).length > 0
                    ? "color-style"
                    : ""
                }`}
              >
                <div
                  className=" d-flex cursorPointer "
                  onClick={() => {
                    setFilterData({
                      ...filterData,
                    });
                    setUserFilter(true);
                  }}
                >
                  <FilterAltOutlinedIcon
                    color={`${
                      Object.values(filterObj.current).filter((element) => {
                        if (element?.length > 0 || isObject(element)) return 1;
                      }).length > 0
                        ? "third"
                        : "primary"
                    }`}
                  />
                </div>
                <div>
                  {Object.values(filterObj.current).filter((element) => {
                    if (element?.length > 0 || isObject(element)) return 1;
                  }).length > 0 && (
                    <div className="d-flex ps-2">
                      <div style={{ border: "1px solid white" }} />
                      <CloseOutlinedIcon
                        color="third"
                        onClick={() => {
                          const clearObj = {
                            UserName: "",
                            OrgFullName: "",
                            Orgcode: "",
                            RegisterdDateFrom: null,
                            RegisterdDateTo: null,
                            LastLoginDateFrom: null,
                            LastLoginDateTo: null,
                            AccessGranted: "",
                            Role: "",
                            ActiveStatus: "",
                          };
                          filterObj.current = {
                            ...clearObj,
                          };
                          setFilterData({
                            ...clearObj,
                          });
                          getAllUser(pageNo, filterData, "");
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {inputValidation(search) ? (
              ""
            ) : (
              <div
                className="validation-error"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Special characters are not allowed.
              </div>
            )}
            <div className="row px-3">
              <UserTable
                lableToSort={lableToSort}
                setUserFilter={setUserFilter}
                userFilter={userFilter}
                filterData={filterData}
                setFilterData={setFilterData}
                getAllUser={getAllUser}
                userData={userData}
                pageNo={pageNo}
                setPageNo={setPageNo}
                setSortColumn={setSortColumn}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                setLoading={setLoading}
                loading={loading}
                search={search}
                filterObj={filterObj}
                userCount={userCount}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
