import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
} from "chart.js";

import { Grid, Typography } from "@mui/material";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { Pie } from "react-chartjs-2";
import { Box } from "@mui/system";
import NodatafoundComp from "../NodatafoundComp";
import { AuthContext } from "../../store/context/AuthProvider";
import { useContext } from "react";

ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, Tooltip);
const DelayedPieChart = ({ filterOptions }) => {
  const colors = [["#ECF5FF"], ["#236C9C"], ["#0A2D44"]];
  const types = ["Early", "Delayed", "OnTime"];
  const [nodataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const context = useContext(AuthContext);
  const prepareData = (data) => {
    let tempData = [];
    let earlyData = [];
    let delayData = [];
    let onTimeData = [];
    data.shipmentCountOnTimeCollection.map((item, ind) => {
      earlyData.push(item.Early);
    });
    data.shipmentCountOnTimeCollection.map((item, ind) => {
      delayData.push(item.Delayed);
    });
    data.shipmentCountOnTimeCollection.map((item, ind) => {
      onTimeData.push(item.OnTime);
    });
    let earlySum = 0;
    let delaySum = 0;
    let onTimeSum = 0;
    earlyData.map((x) => (earlySum += x));
    let earlyPercent = (earlySum / data.TotalCount) * 100;
    delayData.map((x) => (delaySum += x));
    let delayPercent = (delaySum / data.TotalCount) * 100;
    onTimeData.map((x) => (onTimeSum += x));
    let onTimePercent = (onTimeSum / data.TotalCount) * 100;
    tempData.push(earlyPercent, delayPercent, onTimePercent);
    tempData = tempData.map((temp) => temp.toFixed(2));
    setChartData(tempData);
  };
  const getHalfPieChart = () => {
    setLoading(true);
    const body = {
      ShpEtdFrom: "",
      ShpEtdTo: "",
      ShpEtaFrom: "",
      ShpEtaTo: "",
      ShpTransportMode: filterOptions?.filterMode
        ? filterOptions?.filterMode
        : "AIR",
      ShpOriginPortCode: "",
      ShpOriginPortName: "",
      ShpDestinationPortCode: "",
      ShpConsignorCode: "",
      ShpConsigneeCode: "",
      IncoTerm: "",
      ShpDirection: "",
      ShpCarrierCode: filterOptions?.filterCarrier
        ? filterOptions?.filterCarrier
        : "",
      Year: filterOptions?.filterYear ? parseInt(filterOptions?.filterYear) : 0,
    };
    instance
      .post(API_PATH.GET_ANALYTICS_SHIPMENTCOUNTBYTIME, body)
      .then((res) => {
        if (!res.data[0]?.shipmentCountOnTimeCollection.length) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
          prepareData(res.data[0]);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // toast.error(err);
      });
  };
  useEffect(() => {
    if (context.filterLoaded) getHalfPieChart();
  }, [filterOptions, context.filterLoaded]);
  const data = {
    labels: types,
    datasets: [
      {
        data: chartData,
        backgroundColor: colors,
        label: "Percent ",
      },
    ],
  };

  return (
    <>
      {nodataFound ? (
        <>
          <NodatafoundComp />
        </>
      ) : (
        <Grid
          container
          spacing={0}
          sx={{
            height: "80%",
          }}
        >
          <Grid
            item
            xs={8}
            sx={{
              height: "100%",
            }}
          >
            <Pie
              style={
                {
                  // height: "100%",
                }
              }
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: false, // Hide legend
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingBottom: "2rem",
                height: "100%",
              }}
            >
              {colors.map((eachColor, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",

                        background: `${eachColor[0]}`,
                      }}
                    ></div>
                    <div
                      style={{
                        marginLeft: "1.5rem",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "var(--fs-small)",
                        }}
                      >{`${types[index]}`}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DelayedPieChart;
