import { createSlice } from "@reduxjs/toolkit";
const orderPermission = createSlice({
  name: "orderPermission",
  initialState: {
    permisisonOrder: {},
  },
  reducers: {
    updatePermissionData: (state, action) => {
      state.permisisonOrder = action.payload;
      // console.log(action.payload,"payloaded data")
    },
  },
});
export const { updatePermissionData } = orderPermission.actions;
export default orderPermission.reducer;
