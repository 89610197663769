import { createTheme } from "@mui/material/styles";
export const appTheme = createTheme({
  typography: {
    fontFamily: `Poppins`,
  },
  palette: {
    primary: {
      main: "#2c2d74",
      // light: '#757ce8',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    secondary: {
      main: "#da8827",
      // light: '#757ce8',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    ternary: {
      main: "#236c9c",
      // light: '#757ce8',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    third: {
      main: "#fff",
    },
  },
});
