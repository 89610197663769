import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
} from "chart.js";

import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { Pie } from "react-chartjs-2";
import { Box } from "@mui/system";
import NodatafoundComp from "../NodatafoundComp";
import { AuthContext } from "../../store/context/AuthProvider";
import { useContext } from "react";

ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, Tooltip);
const TannagePie = ({ filterOptions, tanType }) => {
  const colors = [["#ECF5FF"], ["#236C9C"], ["#0A2D44"]];
  //   const types = ["Early", "Delayed", "OnTime"];
  const [nodataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);
  // const [tanType, setTanType] = useState("VOL");
  const context = useContext(AuthContext);
  const [unit, setUnit] = useState();
  const [type, setType] = useState();
  //   const prepareData = (data) => {
  //     let tempData = [];
  //     let earlyData = [];
  //     let delayData = [];
  //     let onTimeData = [];
  //     data.shipmentCountOnTimeCollection.map((item, ind) => {
  //       earlyData.push(item.Early);
  //     });
  //     data.shipmentCountOnTimeCollection.map((item, ind) => {
  //       delayData.push(item.Delayed);
  //     });
  //     data.shipmentCountOnTimeCollection.map((item, ind) => {
  //       onTimeData.push(item.OnTime);
  //     });
  //     let earlySum = 0;
  //     let delaySum = 0;
  //     let onTimeSum = 0;
  //     earlyData.map((x) => (earlySum += x));
  //     let earlyPercent = (earlySum / data.TotalCount) * 100;
  //     delayData.map((x) => (delaySum += x));
  //     let delayPercent = (delaySum / data.TotalCount) * 100;
  //     onTimeData.map((x) => (onTimeSum += x));
  //     let onTimePercent = (onTimeSum / data.TotalCount) * 100;
  //     tempData.push(earlyPercent, delayPercent, onTimePercent);
  //     setChartData(tempData);
  //   };
  const getHalfPieChart = () => {
    setLoading(true);
    const body = {
      ShpEtdFrom: "",
      ShpEtdTo: "",
      ShpEtaFrom: "",
      ShpEtaTo: "",
      ShpTransportMode: filterOptions?.filterMode
        ? filterOptions?.filterMode
        : "AIR",
      ShpOriginPortCode: "",
      ShpOriginPortName: "",
      ShpDestinationPortCode: "",
      ShpConsignorCode: "",
      ShpConsigneeCode: "",
      IncoTerm: "",
      ShpDirection: "",
      ShpCarrierCode: filterOptions?.filterCarrier
        ? filterOptions?.filterCarrier
        : "",
      TunnageType: tanType,
      Year: filterOptions?.filterYear ? parseInt(filterOptions?.filterYear) : 0,
    };
    instance
      .post(API_PATH.GET_ANALYTICS_SHIPMENT_TANNAGE, body)
      .then((res) => {
        if (res.data.length) {
          setNoDataFound(false);
          let tempData = [];
          let tempLabel = [];
          res.data[0]?.shipmentTunnageColl.map((item) => {
            tempData.push(item.Totalvalue);
            tempLabel.push(item.ContainerMode);
            const unit = res.data[0]?.shipmentTunnageColl[0].Unit;
            setUnit(unit);
            if (unit === "KG") setType("CHW");
            else if (unit == "Teu") setType("TEU");
            else {
              setType("VOL");
              setUnit("m³");
            }
          });

          setChartData(tempData);
          setChartLabel(tempLabel);
        } else {
          setNoDataFound(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // toast.error(err);
      });
  };
  useEffect(() => {
    if (context.filterLoaded) getHalfPieChart();
  }, [filterOptions, tanType, context.filterLoaded]);
  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: `${type} (${unit})`,
        data: chartData,
        backgroundColor: colors,
      },
    ],
  };

  return (
    <>
      {nodataFound ? (
        <NodatafoundComp />
      ) : (
        <div
          style={{
            height: "80%",
          }}
        >
          {" "}
          <Pie
            style={
              {
                // height: "100%",
              }
            }
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: false, // Hide legend
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default TannagePie;
