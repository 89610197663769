import axios from "axios";
import { useContext } from "react";
import { baseUrl, LOCAL_STORAGE_KEY } from "../Config";
import authHeader, { authHeaderRefresh } from "../services/auth-header";

const instance = axios.create({
  baseURL: baseUrl,
});

let isRefershing = false;
let concurrentReferesh = [];

instance.interceptors.request.use((config) => {
  const token = authHeader();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.data.IsSuccess) {
      const resp = {
        IsSuccess : response?.data?.IsSuccess,
        data: response?.data?.ResponseData ?? [],
        message: response?.data?.Message ?? {},
        totalCount: response?.data?.TotalItem ?? 0,
      };
      return resp;
    } else {
      const resp = {
        data: response?.data?.ResponseData ?? [],
        message: response?.data?.Message ?? {},
        totalCount: response?.data?.TotalItem ?? 0,
      };
      return resp;
    }
  },
  (error) => {
    if (error?.response?.status === 401 && !isRefershing) {
      isRefershing = true;
      const refreshToken = authHeaderRefresh();
      const body = {
        RefreshToken: refreshToken,
      };
      axios
        .post(`${baseUrl}UserAPI/api/login/Refresh-Token`, body)
        .then((res) => {
          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(res.data.ResponseData)
          );
          isRefershing = false;
          return instance(error.config);
        })
        .catch((err) => {
          console.log("Refresh Token Error", err);
          alert("Session timed out!");
          setTimeout(() => {
            isRefershing = false;
          }, 1000);

          localStorage.removeItem(LOCAL_STORAGE_KEY);
          window.location.reload();
          return Promise.reject(error?.response?.data?.Message);
        });
    } else {
      return Promise.reject(error?.response?.data?.Message);
    }
  }
);
// const waitingForTokenRefresh = (config) => {
//   concurrentReferesh.push(config);
// };

// const callRequestOnWait = (re) => {};

export default instance;
