import React from "react";
import "../User.css";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const UserTableHeader = ({
  sortColumn,
  lableToSort,
  filterData,
  sortDirection,
  setSortColumn,
  setSortDirection,
  getAllUser,
}) => {
  return (
    <thead>
      <tr>
        <th className="font-table">
          <span>
            User Name
            {lableToSort.current?.ColName !== "ContactName" ||
            sortColumn === "" ? (
              <ImportExportIcon
                onClick={() => {
                  lableToSort.current = {
                    ColName: "ContactName",
                    ColDir: "Asc",
                  };
                  setSortColumn("ContactName");
                  setSortDirection("Asc");
                  getAllUser(0, filterData, "");
                }}
              />
            ) : (
              <>
                {sortDirection === "Asc" ? (
                  <ArrowDownwardIcon
                    onClick={() => {
                      lableToSort.current = {
                        ColName: "ContactName",
                        ColDir: "Desc",
                      };
                      setSortDirection("Desc");
                      getAllUser(0, filterData, "");
                    }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    onClick={() => {
                      lableToSort.current = {
                        ColName: "ContactName",
                        ColDir: "Asc",
                      };
                      setSortDirection("Asc");
                      getAllUser(0, filterData, "");
                    }}
                  />
                )}
              </>
            )}
          </span>
        </th>
        <th className="font-table">
          {" "}
          <span>Email ID </span>
        </th>
        <th className="font-table">
          <span>
            Organisation
            {lableToSort.current?.ColName !== "OrgFullName" || sortColumn === "" ? (
              <ImportExportIcon
                onClick={() => {
                  lableToSort.current = {
                    ColName: "OrgFullName",
                    ColDir: "Asc",
                  };
                  setSortColumn("OrgFullName");
                  setSortDirection("Asc");
                  getAllUser(0, filterData, "");
                }}
              />
            ) : (
              <>
                {sortDirection === "Asc" ? (
                  <ArrowDownwardIcon
                    onClick={() => {
                      lableToSort.current = {
                        ColName: "OrgFullName",
                        ColDir: "Desc",
                      };
                      setSortDirection("Desc");
                      getAllUser(0, filterData, "");
                    }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    onClick={() => {
                      lableToSort.current = {
                        ColName: "OrgFullName",
                        ColDir: "Asc",
                      };
                      setSortDirection("Asc");
                      getAllUser(0, filterData, "");
                    }}
                  />
                )}
              </>
            )}
          </span>
        </th>
        <th className="font-table">
          <span>Last Login Date</span>
        </th>
        <th className="font-table">
          <span>Registered Date</span>
        </th>
        <th className="font-table">
          <span className="ms-5">Role</span>
        </th>
        <th className="font-table" style={{ textAlign: "center" }}>
          <span>Active status</span>
        </th>
        <th className="font-table" style={{ textAlign: "center" }}>
          <span>Reset password</span>
        </th>
        {/* <th className="font-table" style={{ textAlign: "center" }}>
          <span>Action</span>
        </th> */}
      </tr>
    </thead>
  );
};

export default UserTableHeader;
