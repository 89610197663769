import { styled, Select } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
const ThinSelect = styled(Select)(({ theme }) => ({
  //   fontSize: "2rem",
  //   "& .MuiInputBase-input": {
  //     borderColor: "#ced4da",
  //   },
  padding: "none",
}));
export default function Barchartinput(props) {
  // console.log("props", props);
  return (
    <ThinSelect label={props.label} IconComponent={MenuIcon}>
      {props.children}
    </ThinSelect>
  );
}
