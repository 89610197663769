import axios from "axios";
import { baseOrderUrl, baseUrl, LOCAL_STORAGE_KEY } from "../Config";
import authHeader, { authHeaderRefresh } from "../services/auth-header";

const orderInstance = axios.create({
  baseURL: baseOrderUrl,
});
let isRefershing = false;
let concurrentReferesh = [];
// heres config is the by default config provided by axios to us
orderInstance.interceptors.request.use((config) => {
  const token = authHeader();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

orderInstance.interceptors.response.use(
  (response) => {
    const result = {
      data: response?.data?.Value ?? [],
      message: response?.data?.Messages ?? [],
      totalCount: response?.data?.TotalItem ?? 0,
    };
    return result;
  },
  (error) => {
    if (error?.response?.status === 401 && !isRefershing) {
      isRefershing = true;
      const refreshToken = authHeaderRefresh();
      const body = {
        RefreshToken: refreshToken,
      };
      axios
        .post(`${baseUrl}UserAPI/api/login/Refresh-Token`, body)
        .then((res) => {
          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(res.data.ResponseData)
          );
          isRefershing = false;
          return orderInstance(error.config);
        })
        .catch((err) => {
          console.log("Refresh Token Error", err);
          alert("Session timed out!");
          setTimeout(() => {
            isRefershing = false;
          }, 1000);

          localStorage.removeItem(LOCAL_STORAGE_KEY);
          window.location.reload();
          return Promise.reject(error?.response?.data?.Message);
        });
    } else {
      return Promise.reject(error);
    }
  }
);
export default orderInstance;
