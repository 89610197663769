import React from 'react'
import { tooltipClasses } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import Information from './PorductInformation';
import Typography from '@mui/material/Typography';
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
    "font-size": "12px"
  },
});
const FormatedInformation = (data) => {
  return (
    <>
      {
        Object.keys(data).map((title) => {
          const type = typeof (data[title]) === 'object'
          // when we have to prove the internal title informaiton
          if (type) {
            return (
              <ul>
                {
                  Object.keys(data[title]).map((internalTitile) => {
                    return (
                      <li>
                        {data[title][internalTitile]} <br />
                      </li>
                    )
                  })
                }
              </ul>
            )
          }
          // normal use
          else {
            return (
              <Typography>
                <strong>{title}</strong> {data[title]} <br />
              </Typography>
            )
          }
        }

        )
      }
    </>
  )
}
const CustomTooltip = ({ of, forKey }) => {
  const type = typeof (Information[of][forKey]) === 'object'
  return (
    <CustomWidthTooltip title={type ? FormatedInformation(Information[of][forKey]) : Information[of][forKey]}>
      <HelpOutlineIcon sx={{ fontSize: "15px" }} />
    </CustomWidthTooltip>
  )
}

export default CustomTooltip